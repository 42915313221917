import React from "react";
import { ListItem, createStyles, makeStyles } from "@material-ui/core";
import css from "./ProcMovementsListItem.module.css";
import { ProcMovementType } from "../../../declarations";

export default ProcMovementsListItem;

function ProcMovementsListItem(props: any) {
  const { content: procMovement } = props;

  const classes = useStyles();

  return (
    <div>
      <ListItem
        onClick={() => props.openProcMovement(procMovement.id)}
        className={`${classes.Item} ${css.listItem}`}
        dense
        button
      >
        <div className={css.listItemData}>
          <div className={css.itemName}>{procMovement.processTitle}</div>
          <div className={css.itemEmail}>{procMovement.description}</div>
          <div className={css.itemEmail}>{procMovement.notes}</div>
          <div className={css.itemEmail}>{procMovement.date}</div>
        </div>
      </ListItem>
    </div>
  );
}

interface Props {}

const useStyles = makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      height: "100px"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0"
    },
    icon: {
      color: "#125ad3"
    },
    iconDate: {
      color: "#737373",
      fontSize: "20px"
    },
    button: {
      backgroundColor: "#125ad3",
      alignSelf: "center",
      justifySelf: "center",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    }
  })
);
