import React, { useState, useEffect, useCallback } from "react";
import css from "../Contacts/Contacts.module.css";
import apiCaller from "../../Utils/apiCaller";
import { LinearProgress } from "@material-ui/core";
import Layout from "../Layout";
import GenericList from "../../Components/GenericList/GenericList";
import SearchList from "../../Components/SearchList/SearchList";
import ToolbarList from "../../Components/ToolbarList/ToolbarList";
import { ProcMovementType, IDictionary, DisplayMode, QueryPagination } from "../../declarations";
import ProcMovementsListItem from "./ProcMovementsListItem/ProcMovementsListItem";
import ProcMovement from "./Contact/ProcMovement";

export default ProcMovements;

function ProcMovements() {
  const [state, setState] = useState({ ...initialState });
  const [_, setError] = useState<Error | null>(null);
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC");
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState(0);
  const [openProcMovementId, setOpenProcMovementId] = useState(0);
  const query = state.query;
  const currentMode = state.modes[displayMode];
  const currentQuery = state.queryPaginations[query];

  const patchCurrentMode = (
    fn: (cm: DisplayMode<ProcMovementType>) => Partial<DisplayMode<ProcMovementType>>
  ) => {
    setState(s => {
      const modes = [...s.modes];
      const currentMode = { ...modes[displayMode] };
      const updatedMode = {
        ...currentMode,
        ...fn(currentMode)
      };
      modes[displayMode] = updatedMode;
      return { ...s, modes };
    });
  };

  const patchCurrentQuery = (fn: (cq: QueryPagination) => Partial<QueryPagination>) => {
    setState(s => {
      const { query, queryPaginations } = s;
      const currentQuery = { ...queryPaginations[query] };
      const updatedQuery = {
        ...currentQuery,
        ...fn(currentQuery)
      };
      queryPaginations[query] = updatedQuery;
      return { ...s, queryPaginations };
    });
  };

  useEffect(
    function() {
      let query = "/procMovements";
      if (currentMode.search) {
        query += "/search/" + currentMode.search;
      }

      query += "?" + currentMode.query;
      query += `&order=${order}`;

      setState(s => ({ ...s, query }));
      if (state.queryPaginations[query] === undefined) {
        setState(s => ({
          ...s,
          queryPaginations: {
            ...s.queryPaginations,
            [query]: {
              owns: new Set(),
              loading: false,
              lastPage: 1,
              endReached: false
            }
          }
        }));
      }
    },
    [currentMode, state.queryPaginations, order]
  );

  const fetchNextPage = useCallback(
    function() {
      setLoading(true);
      (async function() {
        const { data, error } = await apiCaller.get(
          query + `&pageLength=20&pageNumber=${currentQuery.lastPage}`
        );
        if (!error) {
          const fetchedProcMovements = data.data as ProcMovementType[];
          if (fetchedProcMovements.length === 0) currentQuery.endReached = true;
          const dataObj: IDictionary<ProcMovementType> = {};
          fetchedProcMovements.forEach(c => {
            dataObj[c.id] = c;
            currentQuery.owns.add(c.id);
          });
          currentQuery.lastPage++;
          setState(s => {
            return {
              ...s,
              fetched: { ...s.fetched, ...dataObj },
              queryPaginations: { ...s.queryPaginations, [query]: currentQuery }
            };
          });
        }
        setLoading(false);
      })();
    },
    [currentQuery, query]
  );

  const [refreshing, setRefreshing] = useState(false);
  const refresh = () => {
    setState(s => ({ ...s, fetched: {}, queryPaginations: {} }));
    setRefreshing(s => !s);
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) fetchNextPage();
  }, [currentQuery, fetchNextPage, refreshing]);

  const search = async (searchString: string) => {
    setState(s => ({ ...s, searchString }));
    patchCurrentMode(() => ({
      search: searchString
    }));
  };

  const isFilterActive = displayMode !== 0 || currentMode.search.length > 0;
  const clearFilters = function() {
    patchCurrentMode(s => ({ ...s, search: "" }));
    setDisplayMode(0);
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const drawerContent = <span>sidebar</span>;
  /* (
    <ContactsSidebar
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      withContactInfo={withContactInfo}
      setWithContactInfo={setWithContactInfo}
    />
  ); */
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent };

  const displayingProcMovements = Object.values(state.fetched).filter(c => {
    let show = currentMode.filterFunction(c) && currentQuery.owns.has(c.id);

    if (show && currentMode.search.length > 0) {
      show = false;
      for (let searchField in c) {
        let value = c[searchField as keyof ProcMovementType] as null | string | number;
        if (value === null) value = "";
        if (typeof value === "number") value = "" + value;
        show = show || new RegExp(`${currentMode.search}`, "i").test(value);
      }
    }
    return show;
  }); /*
    .sort((a, b) => {
      return order === "ASC" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }); */

  return (
    <Layout drawer={drawer}>
      {openProcMovementId ? (
        <ProcMovement
          procMovement={state.fetched[openProcMovementId]}
          setOpenProcMovement={setOpenProcMovementId}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.contactToolbar} />
        <div className={css.contactMain}>
          <div className={css.contactSidebar}>{drawerContent}</div>
          <div className={css.contactList}>
            <SearchList search={search} {...drawer} />
            <div className={css.listFull}>
              <ToolbarList
                setOrder={setOrder}
                order={order}
                isFilterActive={isFilterActive}
                currentSearch={currentMode.search}
                refresh={refresh}
                clearFilters={clearFilters}
              />
              <div className={css.listBody}>
                {loading ? <LinearProgress variant="query" /> : null}

                <GenericList<ProcMovementType, { openProcMovement: (id: number) => void }>
                  component={ProcMovementsListItem}
                  componentProps={{ openProcMovement: setOpenProcMovementId }}
                  fetchNextPage={() => {
                    if (!currentQuery.endReached && !loading) {
                      fetchNextPage();
                    }
                  }}
                  content={displayingProcMovements}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const initialState = {
  query: "/procMovements?",
  fetched: {} as IDictionary<ProcMovementType>,
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    }
  ] as (DisplayMode<ProcMovementType>)[],
  queryPaginations: {} as IDictionary<QueryPagination>
};
