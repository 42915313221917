import React, { HTMLAttributes } from "react";
import Select from "react-select";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { OptionProps } from "react-select/src/components/Option";
import { UserFromGroup } from "../../declarations";
import { Tooltip, Typography } from "@material-ui/core";
import { ValueType } from "react-select/src/types";

export default AutoComplete;

interface OptionType {
  key: any;
  label: string;
  value: string;
}

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props;

  return (
    <TextField
      className={classes.textField}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <div className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      Sem resultados
    </div>
  );
}

const components = {
  Control,
  Menu,
  Option,
  NoOptionsMessage
};

function AutoComplete({ userGroup, setSelectedAssigned, selectedAssigned }: any) {
  const classes = useStyles();
  function handleChange(option: ValueType<OptionType>) {
    setSelectedAssigned((option as any).value);
  }

  const users: OptionType[] = userGroup.map((user: UserFromGroup) => ({
    key: user.id,
    value: user,
    label: user.contactName
  }));

  return (
    <Select
      classes={classes}
      inputId="users-select"
      TextFieldProps={{
        label: "Atribuído à",
        InputLabelProps: {
          htmlFor: "users-select",
          shrink: true
        }
      }}
      options={users}
      components={components}
      value={
        { label: selectedAssigned === null ? "Pesquisar..." : selectedAssigned.contactName } as any
      }
      onChange={handleChange}
    />
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottom: "2px solid #125ad3"
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#125ad3"
      }
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto"
    },
    valueContainer: {
      display: "flex",
      flexWrap: "wrap",
      flex: 1,
      alignItems: "center",
      overflow: "hidden"
    },

    singleValue: {
      fontSize: 16
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      left: 0,
      right: 0
    },
    noOptionsMessage: {
      textAlign: "center",
      color: "#7d7d7d"
    }
  })
);
