function formatCellPhone(field: string): string | undefined {
  const uNumber = field.replace(/\D/g, "");

  // 00 90000-0000

  return uNumber.replace(/(\d{2})(\d{5})(\d{4})/, function(
    _: any,
    ddd: string,
    prefixo: string,
    sufixo: string
  ) {
    return ddd + " " + prefixo + "-" + sufixo;
  });
}

function formatPhone(field: string): string | undefined {
  const uNumber = field.replace(/\D/g, "");
  const freeNumber = /^0+[0-9]+00/.test(uNumber);
  const fNumber = uNumber.startsWith("0") ? uNumber.slice(1) : uNumber;

  //0800 | 0300 | 0500

  if (freeNumber) {
    return uNumber.replace(/(\d{4})(\d{3})(\d{4})/, function(
      _: any,
      arg1: string,
      arg2: string,
      arg3: string
    ) {
      return arg1 + " " + arg2 + " " + arg3;
    });
  }

  // 4004 | 3003 | 0000-0000

  if (freeNumber === false && fNumber.length === 8) {
    return fNumber.replace(/(\d{4})(\d{4})/, function(_: any, prefixo: string, sufixo: string) {
      return prefixo + "-" + sufixo;
    });
  }

  // 00 0000-0000

  if (freeNumber === false) {
    return fNumber.replace(/(\d{2})(\d{4})(\d{4})/, function(
      _: any,
      ddd: string,
      prefixo: string,
      sufixo: string
    ): string {
      return ddd + " " + prefixo + "-" + sufixo;
    });
  }
}

function formatDoc(field: string): string | undefined {
  const uNumber = field.replace(/\D/g, "");

  // CNPJ 00.000.000/0000-00

  if (uNumber.length === 14) {
    return uNumber.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, function(
      _: any,
      arg1: string,
      arg2: string,
      arg3: string,
      arg4: string,
      arg5: string
    ): string {
      return arg1 + "." + arg2 + "." + arg3 + "/" + arg4 + "-" + arg5;
    });
  }

  // CPF 000.000.000-00

  if (uNumber.length === 11) {
    return uNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, function(
      _: any,
      arg1: string,
      arg2: string,
      arg3: string,
      arg4: string
    ): string {
      return arg1 + "." + arg2 + "." + arg3 + "-" + arg4;
    });
  }
}

export { formatCellPhone, formatPhone, formatDoc };
