import React, { Fragment, useState } from "react";
import { AccessTimeOutlined, CheckOutlined, CloseOutlined } from "@material-ui/icons";
import {
  makeStyles,
  createStyles,
  Button,
  Dialog,
  DialogActions,
  TextField,
  DialogContent,
  LinearProgress
} from "@material-ui/core";
import css from "./AssignmentToolbar.module.css";
import apiCaller from "../../../Utils/apiCaller";
import { AssignmentType } from "../../../declarations";

export default AssignmentToolbar;

function AssignmentToolbar({
  clearSelected,
  selectedSet,
  selectAll,
  updateAssignment
}: {
  clearSelected: () => void;
  selectedSet: Set<number>;
  selectAll: () => void;
  updateAssignment: (a: Partial<AssignmentType> & { id: number }) => void;
}) {
  const [questionDialog, setQuestionDialog] = useState({
    open: false,
    loading: false,
    newStatus: -1,
    message: ""
  });

  function submitStatus(newStatus: number) {
    return () => setQuestionDialog(s => ({ ...s, open: true, newStatus }));
  }

  async function setSelectedStatus() {
    setQuestionDialog(s => ({ ...s, loading: true }));

    const assignmentsIds = Array.from(selectedSet.values());

    const { error } = await apiCaller.patch("/assignments/bulk-change-status", {
      newStatus: questionDialog.newStatus,
      assignmentsIds,
      aditionalNotes: questionDialog.message
    });
    setQuestionDialog(s => ({ ...s, loading: false }));

    if (!error) {
      assignmentsIds.forEach(id => updateAssignment({ id, status: questionDialog.newStatus }));
      setQuestionDialog(s => ({ ...s, open: false }));
      clearSelected();
    }
  }

  let newStatusName = "";
  if (questionDialog.newStatus === 0) {
    newStatusName = "Pendente";
  }
  if (questionDialog.newStatus === 1) {
    newStatusName = "Concluído";
  }
  if (questionDialog.newStatus === 2) {
    newStatusName = "Cancelado";
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div className={css.selectTollbar}>
        <div className={css.status}>
          <div className={css.buttonGroup}>
            <Button onClick={submitStatus(0)} className={classes.button}>
              <AccessTimeOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Pendente</span>
            </Button>
            <Button onClick={submitStatus(1)} className={classes.button} title="Concluído">
              <CheckOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Concluído</span>
            </Button>
            <Button onClick={submitStatus(2)} className={classes.button} title="Cancelado">
              <CloseOutlined
                className={classes.statusIcon}
                fontSize="small"
                style={{ color: "#125ad3" }}
              />
              <span className={css.statusBtnTitle}>Cancelado</span>
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        className={classes.dialog}
        open={questionDialog.open}
        onClose={() => setQuestionDialog(s => ({ ...s, open: false }))}
      >
        {questionDialog.loading ? <LinearProgress className={classes.loading} /> : null}
        <DialogContent>
          <div className={css.dialogTitle}>
            Você selecionou <span style={{ fontWeight: 600 }}>{selectedSet.size}</span> prazo(s).
            <br /> Deseja alterar para <span style={{ fontWeight: 600 }}>{newStatusName}</span>?
          </div>
          <div className={css.notes}>
            <TextField
              fullWidth
              onChange={e => {
                e.persist();
                setQuestionDialog(s => ({ ...s, message: e.target.value }));
              }}
              autoFocus
              placeholder="Adicionar nota"
              multiline
              className={classes.textFieldMultiLine}
              value={questionDialog.message}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setSelectedStatus();
            }}
            color="primary"
            autoFocus
            size="small"
          >
            Sim
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={() => {
              setQuestionDialog(s => ({ ...s, open: false }));
            }}
            color="primary"
            autoFocus
            size="small"
          >
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    button: {
      height: "30px",
      display: "grid",
      padding: "0px 8px",
      color: "rgba(0, 0, 0, 0.7)",
      alignContent: "center",
      justifyContent: "center"
    },
    statusIcon: {
      alignSelf: "center",
      justifySelf: "center"
    },
    dialog: {
      "& .MuiDialog-paper": {
        margin: "10px",
        maxWidth: "400px"
      }
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#125ad3"
    },
    textFieldMultiLine: {
      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    }
  })
);
