import React from "react";
import { List, makeStyles, createStyles } from "@material-ui/core";
import css from "./GenericList.module.css";

export default GenericList;

function GenericList<Content, ComponentProps>(props: {
  loading?: boolean;
  content: Array<any>;
  component: any;
  componentProps: ComponentProps;
  fetchNextPage: () => void;
}) {
  const classes = useStyles();
  const listContent =
    !props.loading && props.content.length === 0 ? (
      <div className={css.notice}>Não foram encontrados resultados</div>
    ) : (
      props.content.map(contentItem => {
        return (
          <props.component {...props.componentProps} content={contentItem} key={contentItem.id} />
        );
      })
    );

  const scrollHandler = ({ currentTarget }: { currentTarget: HTMLDivElement }) => {
    const { scrollTop, scrollHeight, clientHeight } = currentTarget;
    const scrollProportion = scrollTop / (scrollHeight - clientHeight);
    if (scrollProportion >= 1) props.fetchNextPage();
  };

  return (
    <List
      onScroll={(e: any) => {
        scrollHandler(e);
      }}
      className={classes.list}
    >
      {listContent}
    </List>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      paddingTop: "0",
      paddingBottom: "0",
      display: "grid",
      gridAutoRows: "max-content",
      height: "calc(100vh - 207px)",
      overflow: "auto",
      "@media only screen and (max-width: 600px)": {
        height: "calc(var(--vh, 1vh) * 100 - 181px)"
      },
      "@media only screen and (max-height: 480px)": {
        height: "calc(var(--vh, 1vh) * 100 - 181px)"
      }
    }
  })
);
