import React, { useState, useEffect, useCallback } from "react";
import css from "../Contacts/Contacts.module.css";
import apiCaller from "../../Utils/apiCaller";
import { LinearProgress } from "@material-ui/core";
import Layout from "../Layout";
import GenericList from "../../Components/GenericList/GenericList";
import SearchList from "../../Components/SearchList/SearchList";
import ToolbarList from "../../Components/ToolbarList/ToolbarList";
import Contact from "./Contact/Contact";
import { ContactType, IDictionary, DisplayMode, QueryPagination } from "../../declarations";
import ContactsListItem from "./ContactsListItem/ContactsListItem";
import ContactsSidebar from "./ContactsSidebar/ContactsSidebar";

export default Contacts;

function Contacts() {
  const [state, setState] = useState({ ...initialState });
  const [_, setError] = useState<Error | null>(null);
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC");
  const [loading, setLoading] = useState(false);
  const [openContactId, setOpenContact] = useState(0);
  const [displayMode, setDisplayMode] = useState(0);
  const [startsWith, setStartsWith] = useState<null | string>(null);
  const query = state.query;
  const currentMode = state.modes[displayMode];
  const currentQuery = state.queryPaginations[query];
  const [withContactInfo, setWithContactInfo] = useState(true);

  const patchCurrentMode = (
    fn: (cm: DisplayMode<ContactType>) => Partial<DisplayMode<ContactType>>
  ) => {
    setState(s => {
      const modes = [...s.modes];
      const currentMode = { ...modes[displayMode] };
      const updatedMode = {
        ...currentMode,
        ...fn(currentMode)
      };
      modes[displayMode] = updatedMode;
      return { ...s, modes };
    });
  };

  const patchCurrentQuery = (fn: (cq: QueryPagination) => Partial<QueryPagination>) => {
    setState(s => {
      const { query, queryPaginations } = s;
      const currentQuery = { ...queryPaginations[query] };
      const updatedQuery = {
        ...currentQuery,
        ...fn(currentQuery)
      };
      queryPaginations[query] = updatedQuery;
      return { ...s, queryPaginations };
    });
  };

  useEffect(
    function() {
      let query = "/contacts";
      if (currentMode.search) {
        query += "/search/" + currentMode.search;
      }

      query += "?" + currentMode.query;

      if (withContactInfo) {
        query += `&withContactInfo=true`;
      }

      if (startsWith) {
        query += `&startsWith=${startsWith}`;
      }

      query += `&order=${order}`;
      setState(s => ({ ...s, query }));
      if (state.queryPaginations[query] === undefined) {
        setState(s => ({
          ...s,
          queryPaginations: {
            ...s.queryPaginations,
            [query]: {
              owns: new Set(),
              loading: false,
              lastPage: 1,
              endReached: false
            }
          }
        }));
      }
    },
    [startsWith, currentMode, state.queryPaginations, order, withContactInfo]
  );

  const fetchNextPage = useCallback(
    function() {
      setLoading(true);
      (async function() {
        const { data, error } = await apiCaller.get(
          query + `&pageLength=20&pageNumber=${currentQuery.lastPage}`
        );
        if (!error) {
          const fetchedContacts = data.data as ContactType[];
          if (fetchedContacts.length === 0) currentQuery.endReached = true;
          const dataObj: IDictionary<ContactType> = {};
          fetchedContacts.forEach(c => {
            dataObj[c.id] = c;
            currentQuery.owns.add(c.id);
          });
          currentQuery.lastPage++;
          setState(s => {
            return {
              ...s,
              fetched: { ...s.fetched, ...dataObj },
              queryPaginations: { ...s.queryPaginations, [query]: currentQuery }
            };
          });
        }
        setLoading(false);
      })();
    },
    [currentQuery, query]
  );

  const [refreshing, setRefreshing] = useState(false);
  const refresh = () => {
    setState(s => ({ ...s, fetched: {}, queryPaginations: {} }));
    setRefreshing(s => !s);
  };

  useEffect(() => {
    if (currentQuery && !currentQuery.endReached && currentQuery.owns.size === 0) fetchNextPage();
  }, [currentQuery, fetchNextPage, refreshing]);

  const search = async (searchString: string) => {
    setState(s => ({ ...s, searchString }));
    patchCurrentMode(() => ({
      search: searchString
    }));
  };

  const isFilterActive =
    displayMode !== 0 || !!startsWith || currentMode.search.length > 0 || withContactInfo;
  const clearFilters = function() {
    setWithContactInfo(false);
    setStartsWith(null);
    patchCurrentMode(s => ({ ...s, search: "" }));
    setDisplayMode(0);
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const drawerContent = (
    <ContactsSidebar
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      withContactInfo={withContactInfo}
      setWithContactInfo={setWithContactInfo}
      setDrawer={setDrawer}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent };

  const displayingContacts = Object.values(state.fetched)
    .filter(c => {
      let show =
        currentMode.filterFunction(c) &&
        (!startsWith || c.name.indexOf(startsWith) === 0) &&
        currentQuery.owns.has(c.id);

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField in c) {
          let value = c[searchField as keyof ContactType] as null | string | number;
          if (value === null) value = "";
          if (typeof value === "number") value = "" + value;
          show = show || new RegExp(`${currentMode.search}`, "i").test(value);
        }
      }
      return show;
    })
    .sort((a, b) => {
      return order === "ASC" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    });

  const updateContact = useCallback(function(patchObj: Partial<ContactType> & { id: number }) {
    setState(s => ({
      ...s,
      fetched: { ...s.fetched, [patchObj.id]: { ...s.fetched[patchObj.id], ...patchObj } }
    }));
  }, []);

  return (
    <Layout drawer={drawer}>
      {openContactId ? (
        <Contact
          openContactId={openContactId}
          updateContact={updateContact}
          contact={state.fetched[openContactId]}
          setOpenContact={setOpenContact}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.contactToolbar} />
        <div className={css.contactMain}>
          <div className={css.contactSidebar}>{drawerContent}</div>
          <div className={css.contactList}>
            <SearchList search={search} {...drawer} />
            <div className={css.listFull}>
              <ToolbarList
                setOrder={setOrder}
                order={order}
                isFilterActive={isFilterActive}
                currentSearch={currentMode.search}
                refresh={refresh}
                clearFilters={clearFilters}
              />
              <div className={css.listBody}>
                {loading ? <LinearProgress variant="query" /> : <div className={css.loading} />}

                <GenericList<ContactType, { openContact: (id: number) => void }>
                  loading={loading}
                  component={ContactsListItem}
                  componentProps={{ openContact: setOpenContact }}
                  fetchNextPage={() => {
                    if (!currentQuery.endReached && !loading) {
                      fetchNextPage();
                    }
                  }}
                  content={displayingContacts}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const initialState = {
  query: "/contacts?",
  fetched: {} as IDictionary<ContactType>,
  modes: [
    {
      label: "Todos",
      filterFunction: () => true,
      query: "",
      search: ""
    },
    {
      label: "Clientes",
      filterFunction: c => !!c.contactClass && c.contactClass.includes("C"),
      query: "contactClass=C",
      search: ""
    },
    {
      label: "Possíveis Clientes",
      filterFunction: c => !!c.contactClass && c.contactClass.includes("S"),
      query: "contactClass=S",
      search: ""
    },
    {
      label: "Advogados",
      filterFunction: c => c.contactClass.includes("A"),
      query: "contactClass=A",
      search: ""
    },
    {
      label: "Estagiários",
      filterFunction: c => c.contactClass.includes("E"),
      query: "contactClass=E",
      search: ""
    },
    {
      label: "Administrativos",
      filterFunction: c => c.contactClass.includes("U"),
      query: "contactClass=U",
      search: ""
    },
    {
      label: "Partes",
      filterFunction: c => c.contactClass.includes("P"),
      query: "contactClass=P",
      search: ""
    },
    {
      label: "Fornecedores",
      filterFunction: c => c.contactClass.includes("F"),
      query: "contactClass=F",
      search: ""
    },
    {
      label: "Correspondentes",
      filterFunction: c => c.contactClass.includes("D"),
      query: "contactClass=D",
      search: ""
    },
    {
      label: "Captadores",
      filterFunction: c => c.contactClass.includes("R"),
      query: "contactClass=R",
      search: ""
    },
    {
      label: "Outros",
      filterFunction: c => c.contactClass.includes("O"),
      query: "contactClass=O",
      search: ""
    }
  ] as (DisplayMode<ContactType>)[],
  queryPaginations: {} as IDictionary<QueryPagination>
};
