import React from "react";
import css from "./Recovery.module.css";
import Title from "../../../Components/Title/Title";
import { Link } from "react-router-dom";

export default invalidToken;

function invalidToken() {
  return (
    <div className={css.containerNotice}>
      <Title className={css.titleRecovery}>Oooops!</Title>
      <p className={css.noticeRecovery}>O link de recuperação de senha é inválido ou expirou!</p>

      <p className={css.noticeRecovery}>
        Você poderá solicitar uma nova
        <Link className={css.link} to="/auth/recuperacao">
          recuperação de senha
        </Link>
        ou realizar o login.
      </p>
      <Link className={css.link} to="/auth/Login">
        Ir para Login
      </Link>
    </div>
  );
}
