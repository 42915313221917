import React, { useState, ChangeEvent, SyntheticEvent } from "react";
import {
  Input,
  IconButton,
  Hidden,
  Button,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core";
import css from "./SearchList.module.css";
import { FilterListOutlined, SearchOutlined } from "@material-ui/icons";

export default SearchList;

function SearchList(props: Props) {
  const [valorInicial, setValor] = useState<State>({
    searchString: ""
  });

  const handleSubmit = async (e: SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    props.search(valorInicial.searchString);
  };

  const handleChange = (name: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
    setValor({ ...valorInicial, [name]: event.target.value });
  };

  const classes = useStyles();

  return (
    <div className={css.listSearch}>
      <div className={css.searchContainer}>
        <form className={css.search} onSubmit={handleSubmit}>
          <div className={css.filterMenu}>
            <Hidden lgUp>
              <IconButton
                className={classes.icon}
                onClick={() => {
                  props.setDrawer(true);
                }}
              >
                <FilterListOutlined />
              </IconButton>
            </Hidden>
          </div>
          <SearchOutlined className={css.iconSearch} color="action" />

          <Input
            className={css.inputSearch}
            onChange={handleChange("searchString")}
            placeholder="Pesquisar por..."
            disableUnderline
            fullWidth
            value={valorInicial.searchString}
          />
          <Button type="submit" className={`${classes.button}`} variant="contained" color="primary">
            Pesquisar
          </Button>
        </form>
      </div>
    </div>
  );
}

interface State {
  searchString: string;
}

interface Props {
  setDrawer: (state: boolean) => void;
  search: (str: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "#125ad3",
      gridColumn: 4,
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
      "&:hover": {
        backgroundColor: "#043bbf"
      }
    },
    icon: {
      padding: "4px"
    }
  })
);
