import React from "react";
import css from "./ContactsSidebar.module.css";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  createStyles,
  ListSubheader,
  Divider,
  Switch,
  Button
} from "@material-ui/core";
import {
  ContactsOutlined,
  ReorderOutlined,
  PersonOutlined,
  AssignmentIndOutlined,
  FilterListOutlined
} from "@material-ui/icons";

export default ContactsSidebar;

function ContactsSidebar({
  displayMode,
  setDisplayMode,
  setDrawer,
  withContactInfo,
  setWithContactInfo,
  clearFilters,
  isFilterActive
}: Props) {
  const closeDrawer = () => (setDrawer ? setDrawer(false) : null);
  const classes = useStyles();
  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <ContactsOutlined className={classes.IconTitle} />
          <p className={css.sidebarSupTitle}>Contatos</p>
        </div>
        <div className={css.filterContactInfo}>
          <span className={css.filterContactTitle}>Sem inf. de contato</span>
          <Switch
            checked={!withContactInfo}
            onClick={() => {
              setWithContactInfo(s => !s);
            }}
            value={withContactInfo}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            className={classes.switchBase}
          />
        </div>
      </div>

      <div className={css.sidebarInf}>
        <List className={classes.sidebarInfList}>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 0}
            onClick={() => {
              setDisplayMode(0);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReorderOutlined />
            </ListItemIcon>
            <ListItemText primary="Todos" />
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subHeader} disableSticky={true}>
            <ListItemIcon className={classes.subHeaderIcon}>
              <PersonOutlined />
            </ListItemIcon>
            <ListItemText className={classes.subHeaderText} primary="Clientes" />
          </ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 1}
            onClick={() => {
              setDisplayMode(1);
              closeDrawer();
            }}
          >
            <ListItemText primary="Clientes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 2}
            onClick={() => {
              setDisplayMode(2);
              closeDrawer();
            }}
          >
            <ListItemText primary="Possíveis clientes" />
          </ListItem>
          <Divider />
          <ListSubheader className={classes.subHeader} disableSticky={true}>
            <ListItemIcon className={classes.subHeaderIcon}>
              <AssignmentIndOutlined />
            </ListItemIcon>
            <ListItemText className={classes.subHeaderText} primary="Equipe" />
          </ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 3}
            onClick={() => {
              setDisplayMode(3);
              closeDrawer();
            }}
          >
            <ListItemText primary="Advogados" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 4}
            onClick={() => {
              setDisplayMode(4);
              closeDrawer();
            }}
          >
            <ListItemText primary="Estagiários" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 5}
            onClick={() => {
              setDisplayMode(5);
              closeDrawer();
            }}
          >
            <ListItemText primary="Administrativos" />
          </ListItem>
          <Divider />
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 6}
            onClick={() => {
              setDisplayMode(6);
              closeDrawer();
            }}
          >
            <ListItemText primary="Partes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 7}
            onClick={() => {
              setDisplayMode(7);
              closeDrawer();
            }}
          >
            <ListItemText primary="Fornecedores" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 8}
            onClick={() => {
              setDisplayMode(8);
              closeDrawer();
            }}
          >
            <ListItemText primary="Correspondentes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 9}
            onClick={() => {
              setDisplayMode(9);
              closeDrawer();
            }}
          >
            <ListItemText primary="Captadores" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 10}
            onClick={() => {
              setDisplayMode(10);
              closeDrawer();
            }}
          >
            <ListItemText primary="Outros" />
          </ListItem>
          <Divider />
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  clearFilters?: () => void;
  isFilterActive?: boolean;
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setDrawer?: (state: boolean) => void;
  withContactInfo: boolean;
  setWithContactInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      width: "180px",
      gridColumn: "1",
      gridRow: "2",
      alignSelf: "start",
      justifySelf: "center"
    },

    label: {
      "&.Mui-focused": {
        color: "#125ad3"
      }
    },
    switchBase: {
      "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: "#125ad3"
      },
      "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#125ad3"
      },
      "& .MuiSwitch-switchBase": {
        color: "#c6c6c6"
      }
    },
    select: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      }
    },

    listItemButton: {
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    },
    listItemIcon: {
      minWidth: "40px"
    },
    IconTitle: {
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center",
      justifySelf: "center",
      marginRight: "10px",
      fontSize: "40px",
      color: "#1a5dce",
      "@media only screen and (min-width: 600px)": {
        width: "0.8em",
        height: "0.8em"
      },
      "@media only screen and (max-width: 600px)": {
        width: "0.7em",
        height: "0.7em"
      }
    },

    sidebarInfList: {
      height: "calc(var(--vh, 1vh) * 100 - 265px)",
      padding: "0px"
    },

    button: {
      backgroundColor: "#125ad3",
      fontSize: "0.8rem",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#0039c4"
      }
    },
    iconFilter: {
      color: "#fff",
      fontSize: "1.2rem",
      padding: "0 5px"
    },
    subHeader: {
      display: "grid",
      gridTemplateColumns: "min-content auto"
    },
    subHeaderIcon: {
      gridRow: "1",
      gridColumn: "1",
      minWidth: "30px",
      alignSelf: "center"
    },
    subHeaderText: {
      gridRow: "1",
      gridColumn: "2"
    }
  })
);
