import React, { useState, useEffect, useCallback } from "react";
import css from "./Assignments.module.css";
import apiCaller from "../../Utils/apiCaller";
import AssignmentsSidebar from "./AssignmentsSidebar/AssignmentsSidebar";
import { LinearProgress, makeStyles, createStyles } from "@material-ui/core";
import Layout from "../Layout";
import Assignment from "./Assignment/Assignment";
import GenericList from "../../Components/GenericList/GenericList";
import AssignmentsListItem from "./AssignmentListItem/AssignmentsListItem";
import SearchList from "../../Components/SearchList/SearchList";
import ToolbarList from "../../Components/ToolbarList/ToolbarList";
import normalizeDate from "../../Utils/normalizeDate";
import {
  IDictionary,
  AssignmentType,
  AssignmentFromRequest,
  Result,
  Period,
  QueryPagination,
  DisplayMode,
  UserFromGroup
} from "../../declarations";
import AssignmentToolbar from "./AssignmentToolbar/AssignmentToolbar";

export default Assignments;

function Assignments(props: { userGroup: { id: number; contactName: string }[] }) {
  const { userGroup } = props;
  const [state, setState] = useState({ ...initialState, modes: [...initialState.modes] });
  const [_, setError] = useState<Error | null>(null);
  const [selectedAssigned, _setSelectedAssigned] = useState<UserFromGroup | null>(null);
  const currentQuery = state.queryPaginations[state.query];
  const [displayMode, setDisplayMode] = useState(1);
  const currentMode = state.modes[displayMode];
  const [order, setOrder] = useState<"ASC" | "DESC">("ASC");
  const [selectedSet, setSelectedSet] = useState(new Set<number>());

  const toggleSelected = function(id: number) {
    setSelectedSet(s => {
      const ns = new Set([...Array.from(s.values())]);
      if (ns.has(id)) {
        ns.delete(id);
        return ns;
      } else {
        ns.add(id);
      }
      return ns;
    });
  };

  const patchCurrentMode = (
    fn: (cm: DisplayMode<AssignmentType>) => Partial<DisplayMode<AssignmentType>>
  ) => {
    setState(s => {
      const modes = [...s.modes];
      const currentMode = { ...modes[displayMode] };
      const updatedMode = {
        ...currentMode,
        ...fn(currentMode)
      };
      modes[displayMode] = updatedMode;
      return { ...s, modes };
    });
  };

  const patchCurrentQuery = (fn: (cq: QueryPagination) => Partial<QueryPagination>) => {
    setState(s => {
      const { query, queryPaginations } = s;
      const currentQuery = { ...queryPaginations[query] };
      const updatedQuery = {
        ...currentQuery,
        ...fn(currentQuery)
      };
      queryPaginations[query] = updatedQuery;
      return { ...s, queryPaginations };
    });
  };

  const setSelectedAssigned = (user: UserFromGroup | null) => {
    _setSelectedAssigned(user);
  };

  const _fetchNextPage = useCallback(() => {
    patchCurrentQuery(cq => {
      return { loading: true };
    });

    setState(s => {
      let { fetched, query } = s;
      const queryPaginations = { ...s.queryPaginations };
      const currentQuery = { ...queryPaginations[query] };
      const { lastPage } = currentQuery;
      let path = query;
      path += `&pageNumber=${lastPage}&pageLength=20`;

      (async () => {
        let { data, error } = (await apiCaller.get(path)) as Result<{
          data: AssignmentFromRequest[];
        }>;
        if (error) {
          setError(error);
        } else if (data) {
          const assignments = data.data;
          const dataObj: IDictionary<AssignmentType> = {};
          assignments.forEach(element => {
            dataObj[element.id] = normalizeAssignment(element);
            currentQuery.owns.add(element.id);
          });

          fetched = { ...fetched, ...dataObj };
          if (assignments.length === 0) {
            currentQuery.endReached = true;
          }
          currentQuery.lastPage++;
        }
        currentQuery.loading = false;

        setState(ss => {
          return {
            ...ss,
            fetched: { ...fetched, ...ss.fetched },
            queryPaginations: { ...ss.queryPaginations, [query]: { ...currentQuery } }
          };
        });
      })();
      return s;
    });
  }, []);

  const search = async (searchString: string) => {
    setState(s => ({ ...s, searchString }));
    patchCurrentMode(() => ({
      search: searchString
    }));
  };

  const [refreshing, setRefreshing] = useState(1);
  const refresh = () => {
    setState(s => {
      const newState = {
        ...s,
        fetched: {},
        queryPaginations: {
          "/assignments?": {
            lastPage: 1,
            owns: new Set([0]),
            endReached: false,
            loading: false
          }
        }
      };
      return newState;
    });
    setRefreshing(s => s + 1);
  };

  const [searchPeriod, _setSearchPeriod] = useState<Period>({
    fromDate: null,
    toDate: null
  });

  const setSearchPeriod = (value: Date | null, field: "fromDate" | "toDate") => {
    _setSearchPeriod(s => {
      const newPeriod = {
        ...s,
        toDate: s.fromDate === null ? value : s.toDate,
        [field]: value
      };
      return newPeriod;
    });
  };

  const [showDrawer, setDrawer] = useState<boolean>(false);
  const toggleDrawer = function() {
    setDrawer(s => !s);
  };

  const isFilterActive =
    searchPeriod.fromDate !== null ||
    displayMode !== 0 ||
    currentMode.search !== "" ||
    selectedAssigned !== null;

  const clearFilters = () => {
    _setSearchPeriod({ fromDate: null, toDate: null });
    setSelectedAssigned(null);
    setState(s => ({ ...s, modes: [...initialState.modes] }));
    setDisplayMode(0);
  };

  const drawerContent = (
    <AssignmentsSidebar
      clearFilters={clearFilters}
      isFilterActive={isFilterActive}
      userGroup={userGroup}
      selectedAssigned={selectedAssigned}
      setSelectedAssigned={setSelectedAssigned}
      setSelectedDate={setSearchPeriod}
      selectedDate={searchPeriod}
      displayMode={displayMode}
      setDisplayMode={setDisplayMode}
      setDrawer={setDrawer}
    />
  );
  const drawer = { showDrawer, setDrawer, toggleDrawer, drawerContent };

  const assignmentSearchFields = [
    "author",
    "processNumber",
    "counterPart",
    "subject",
    "notes",
    "location",
    "processNumberUnif"
  ];

  const loading = !currentQuery || currentQuery.loading;
  const displayingAssignments = Object.values(state.fetched)
    .filter(a => {
      let show: boolean =
        currentMode.filterFunction(a) &&
        (currentQuery && currentQuery.owns && currentQuery.owns.has(a.id));
      if (selectedAssigned !== null) show = show && a.assignedUserId === selectedAssigned.id;
      if (
        searchPeriod.fromDate !== null &&
        searchPeriod.toDate !== null &&
        ![1, 5, 6].includes(displayMode)
      ) {
        show =
          show &&
          a.startDate.getTime() <= searchPeriod.toDate.getTime() &&
          a.startDate.getTime() >= searchPeriod.fromDate.getTime();
      }

      if (show && currentMode.search.length > 0) {
        show = false;
        for (let searchField of assignmentSearchFields) {
          let value = a[searchField as keyof AssignmentType] as null | string | number;
          if (value === null) value = "";
          if (typeof value === "number") value = "" + value;
          show = show || new RegExp(`${currentMode.search}`, "i").test(value);
        }
      }
      return show;
    })
    .sort((a, b) => {
      const aDate = new Date(a.startDate.getTime());
      const bDate = new Date(b.startDate.getTime());

      aDate.setHours(+a.startTime.slice(0, 2), +a.startTime.slice(3, 5));
      bDate.setHours(+b.startTime.slice(0, 2), +b.startTime.slice(3, 5));

      if (order === "ASC") {
        return aDate.getTime() - bDate.getTime();
      } else {
        return bDate.getTime() - aDate.getTime();
      }
    });

  const updateQuery = () => {
    let query = "/assignments";
    if (currentMode.search !== "") query += "/search/" + currentMode.search;
    query += `?${currentMode.query}`;
    if (
      searchPeriod.fromDate !== null &&
      searchPeriod.toDate !== null &&
      displayMode < 5 &&
      displayMode !== 1
    )
      query += `&fromDate=${searchPeriod.fromDate.toDateString()}&toDate=${searchPeriod.toDate.toDateString()}`;
    if (selectedAssigned !== null) query += `&assignedUserId=${selectedAssigned.id}`;
    query += "&dateOrder=" + order;

    setState(s => {
      const { queryPaginations } = s;
      let currentQuery = queryPaginations[query];

      if (currentQuery === undefined) {
        currentQuery = {
          lastPage: 1,
          owns: new Set(),
          endReached: false,
          loading: false
        };
      }
      queryPaginations[query] = currentQuery;
      if (!currentQuery.endReached && currentQuery.owns.size === 0) _fetchNextPage();
      return { ...s, query, queryPaginations };
    });
  };

  useEffect(updateQuery, [
    _fetchNextPage,
    selectedAssigned,
    selectedAssigned,
    currentMode.query,
    currentMode.search,
    searchPeriod.fromDate,
    searchPeriod.toDate,
    order,
    refreshing
  ]);

  const updateAssignment = useCallback((assignment: Partial<AssignmentType> & { id: number }) => {
    if (assignment.recorrency) {
      assignment.endDate = assignment.startDate;
    }
    return setState(s => {
      return {
        ...s,
        fetched: { ...s.fetched, [assignment.id]: { ...s.fetched[assignment.id], ...assignment } }
      };
    });
  }, []);

  const setOpenAssignment = (id: number) => setState(s => ({ ...s, openAssignmentId: id }));
  const classes = useStyles();

  return (
    <Layout drawer={drawer}>
      {state.openAssignmentId ? (
        <Assignment
          openAssignmentId={state.openAssignmentId}
          updateAssignment={updateAssignment}
          assignment={state.fetched[state.openAssignmentId]}
          setOpenAssignment={setOpenAssignment}
        />
      ) : null}
      <div className={css.main}>
        <div className={css.assignmentToolbar} />
        <div className={css.assignmentMain}>
          <div className={css.assignmentSidebar}>{drawerContent}</div>
          <div className={css.assignmentList}>
            <SearchList search={search} {...drawer} />
            <div className={css.listFull}>
              <ToolbarList
                setOrder={setOrder}
                order={order}
                isFilterActive={isFilterActive}
                currentSearch={currentMode.search}
                refresh={refresh}
                clearFilters={clearFilters}
              />
              <div className={css.listBody}>
                {loading ? (
                  <LinearProgress variant="query" className={classes.loading} />
                ) : (
                  <div className={css.loading} />
                )}
                <GenericList<
                  AssignmentType,
                  {
                    updateAssignment: any;
                    openAssignment: (id: number) => void;
                    toggleSelected: (id: number) => void;
                    selectedSet: Set<number>;
                  }
                >
                  loading={loading}
                  component={AssignmentsListItem}
                  componentProps={{
                    updateAssignment,
                    openAssignment: setOpenAssignment,
                    toggleSelected,
                    selectedSet
                  }}
                  fetchNextPage={() => {
                    if (!currentQuery.endReached && !loading) {
                      _fetchNextPage();
                    }
                  }}
                  content={displayingAssignments}
                />
              </div>
              {selectedSet.size > 0 ? (
                <AssignmentToolbar
                  clearSelected={() => {
                    setSelectedSet(() => new Set([]));
                  }}
                  updateAssignment={updateAssignment}
                  selectAll={() => setSelectedSet(new Set(displayingAssignments.map(a => a.id)))}
                  selectedSet={selectedSet}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    }
  })
);

const initialState = {
  query: `/assignments?fromDate=${new Date().toDateString()}&toDate=${new Date().toDateString()}`,
  queryPaginations: {
    [`/assignments?fromDate=${new Date().toDateString()}&toDate=${new Date().toDateString()}`]: {
      lastPage: 1,
      owns: new Set(),
      endReached: false,
      loading: false
    }
  } as IDictionary<QueryPagination>,
  openAssignmentId: 0,
  fetched: {} as IDictionary<AssignmentType>,
  searchString: "",
  modes: [
    {
      label: "Todos",
      query: "",
      filterFunction: () => true,
      search: ""
    },
    {
      label: "Hoje",
      query: `fromDate=${new Date().toDateString()}&toDate=${new Date().toDateString()}`,
      filterFunction: (a: AssignmentType) =>
        new Date().toDateString() === a.startDate.toDateString(),
      search: ""
    },
    {
      label: "Pendentes",
      query: "status=0",
      filterFunction: (a: AssignmentType) => +a.status === 0,
      search: ""
    },
    {
      label: "Cancelados",
      query: "status=2",
      filterFunction: (a: AssignmentType) => +a.status === 2,
      search: ""
    },
    {
      label: "Concluidos",
      query: "status=1",
      filterFunction: (a: AssignmentType) => +a.status === 1,
      search: ""
    },
    {
      label: "Proximos 3 dias",
      lastPage: 0,
      query: `fromDate=${new Date(new Date().setHours(24)).toDateString()}&toDate=${new Date(
        new Date().setHours(24 * 3)
      ).toDateString()}`,
      filterFunction: (a: AssignmentType) => {
        const date = a.startDate;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateDiff = date.getTime() - today.getTime();
        return dateDiff > 0 && dateDiff < 3 * 24 * 60 * 60 * 1000;
      },
      search: ""
    },
    {
      label: "Proximos 7 dias",
      query: `fromDate=${new Date(new Date().setHours(24)).toDateString()}&toDate=${new Date(
        new Date().setHours(24 * 7)
      ).toDateString()}`,
      filterFunction: (a: AssignmentType) => {
        const date = a.startDate;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const dateDiff = date.getTime() - today.getTime();
        return dateDiff > 0 && dateDiff < 7 * 24 * 60 * 60 * 1000;
      },
      search: ""
    }
  ]
};

export function normalizeAssignment(fetchedAssignment: AssignmentFromRequest): AssignmentType {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    notes,
    subject,
    location,
    recorrency
  } = fetchedAssignment;
  return {
    ...fetchedAssignment,
    startDate: new Date(startDate),
    endDate: normalizeDate(recorrency ? startDate : endDate),
    startTime: startTime.slice(0, 5),
    endTime: endTime.slice(0, 5),
    notes: notes === null ? "" : notes,
    subject: subject === null ? "" : subject,
    location: location === null ? "" : location
  };
}
