import React, { ReactElement, useEffect, useState, useCallback } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Auth from "./Auth";
import NotFound from "./NotFound";
import Assignments from "./Assignments";
import apiCaller from "../Utils/apiCaller";
import { CircularProgress } from "@material-ui/core";
import { User, UserFromGroup, UserContext } from "../declarations";
import NoticeSnackbar from "../Components/NoticeSnackbar/NoticeSnackbar";
import { makeStyles, createStyles } from "@material-ui/styles";
import Contacts from "./Contacts/Contacts";
import ProcMovements from "./ProcMovements/ProcMovements";

export default Root;

export const userContext = React.createContext<UserContext>({
  user: null,
  fetchUser: async () => {},
  setUser: () => {},
  logout: () => {}
});

function Root(): ReactElement {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [userGroup, setUserGroup] = useState<UserFromGroup[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchUsersArray = useCallback(async () => {
    const { data, error } = await apiCaller.get("/users/");
    if (error) {
    } else {
      setUserGroup(s => [
        ...s,
        ...data.data.sort(function(a: UserFromGroup, b: UserFromGroup) {
          return a.contactName.localeCompare(b.contactName);
        })
      ]);
    }
  }, []);

  const fetchUser = useCallback(async () => {
    const { error, data } = await apiCaller.get("/users/me");
    if (error) {
      apiCaller.setAuthToken("");
    } else {
      setUser(data);
      await fetchUsersArray();
    }
    setLoading(false);
  }, [fetchUsersArray]);

  const logout = () => {
    apiCaller.setAuthToken("");
    setUser(null);
    window.location.reload();
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
    apiCaller.axiosInstance.interceptors.response.use(
      r => r,
      error => {
        if (error.message === "Network Error" || error.response.status > 499) {
          if (error.message === "Network Error") setError(error.message);
          else {
            setError(error.response.data.message);
          }
          return Promise.reject(1);
        } else if (error.response) {
          return Promise.reject(error.response.data.message);
        }
      }
    );
    fetchUser();
  }, [fetchUser, setError]);

  const classes = useStyles();

  if (loading) return <CircularProgress className={classes.loading} />;
  return (
    <BrowserRouter>
      <userContext.Provider value={{ user, fetchUser, setUser, logout }}>
        <Switch>
          <Redirect path="/" exact to="/auth/login" />
          <Route component={NotFound} path="/not-found" exact />
          <Route
            path="/auth"
            render={props => (user ? <Redirect to="/prazos" /> : <Auth {...props} />)}
          />
          <Route path="/prazos" exact>
            {user ? <Assignments userGroup={userGroup} /> : <Redirect to="/auth/login" />}
          </Route>
          <Route path="/contatos" exact>
            {user ? <Contacts /> : <Redirect to="/auth/login" />}
          </Route>
          <Route path="/andamentos" exact>
            {user ? <ProcMovements /> : <Redirect to="/auth/login" />}
          </Route>
          <Redirect to="/not-found" />
        </Switch>
        <NoticeSnackbar error={error} setError={setError} />
      </userContext.Provider>
    </BrowserRouter>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3"
    }
  })
);
