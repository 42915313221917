import React, { Fragment } from "react";
import { ListItem, createStyles, makeStyles, Checkbox } from "@material-ui/core";
import css from "./AssignmentsListItem.module.css";
import ptBR from "date-fns/locale/pt-BR";
import { formatWithOptions } from "date-fns/fp";
import { DateRangeOutlined, HowToRegOutlined } from "@material-ui/icons";
import { AssignmentType } from "../../../declarations";

export default AssignmentsListItem;

function AssignmentsListItem(props: {
  content: AssignmentType;
  openAssignment: (id: number) => void;
  toggleSelected: (id: number) => void;
  selectedSet: Set<number>;
}) {
  const { content: assignment } = props;
  const newDate = assignment.startDate;
  const currentYear = new Date().getFullYear();
  const otherYears = newDate.getFullYear();

  const startDate =
    currentYear === otherYears
      ? formatWithOptions({ locale: ptBR }, "EEE', 'd 'de' MMMM", newDate)
      : formatWithOptions({ locale: ptBR }, "dd/MM/yyyy", newDate);

  const dataAssignment = new Date().toDateString() === newDate.toDateString() ? "Hoje" : startDate;

  const classes = useStyles();

  let colorHex = assignment.color.toString(16);
  for (let i = colorHex.length; i < 6; i++) {
    colorHex = "0" + colorHex;
  }
  colorHex = colorHex.slice(4, 6) + colorHex.slice(2, 4) + colorHex.slice(0, 2);

  const statusStyled = () => {
    switch (assignment.status) {
      case 0:
        return undefined;
      case 1:
        return { color: "rgba(0, 0, 0, 0.5)", textDecoration: "line-through" };
      case 2:
        return { color: "rgba(255, 0, 0, 0.5)", textDecoration: "line-through" };
    }
  };

  const statusStyledDate = () => {
    switch (assignment.status) {
      case 0:
        return undefined;
      case 1:
        return { color: "rgba(0, 0, 0, 0.5)" };
      case 2:
        return { color: "rgba(255, 0, 0, 0.5)" };
    }
  };

  return (
    <Fragment>
      <ListItem
        onClick={() => {
          props.openAssignment(assignment.id);
        }}
        className={`${classes.Item} ${css.listItem}`}
        dense
        button
      >
        <div className={css.listItemColor} style={{ backgroundColor: `#${colorHex}` }} />
        <div className={css.selectItem}>
          {assignment.permissionLevel >= 1 ? (
            <Checkbox
              checked={props.selectedSet.has(assignment.id)}
              color="primary"
              className={classes.checkBox}
              onClick={e => {
                e.stopPropagation();
                props.toggleSelected(assignment.id);
              }}
            />
          ) : null}
        </div>
        <div className={css.listItemBody}>
          <div className={css.itemSubject} style={statusStyled()}>
            {assignment.subject}
          </div>
          <div className={css.itemDesc} style={statusStyled()}>
            {assignment.notes}
          </div>
          <div className={css.listItemDate} style={statusStyledDate()}>
            <div className={css.itemDate}>{dataAssignment}</div>
            {!assignment.allDay ? (
              <div className={css.itemHour}> {assignment.startTime} </div>
            ) : null}
            <div title="Recorrência" className={css.itemRecorrency}>
              {assignment.recorrency ? (
                <DateRangeOutlined className={classes.iconDate} />
              ) : (
                undefined
              )}
            </div>
          </div>
        </div>
        <div title="Atribuído à você" className={css.listItemTools}>
          {assignment.linkedUserId === assignment.assignedUserId ? (
            <HowToRegOutlined className={classes.icon} />
          ) : (
            undefined
          )}
        </div>
      </ListItem>
    </Fragment>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      height: "90px"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0"
    },

    icon: {
      color: "#125ad3"
    },

    iconDate: {
      color: "#737373",
      fontSize: "1.1rem"
    },
    button: {
      backgroundColor: "#125ad3",
      alignSelf: "center",
      justifySelf: "center",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    },
    checkBox: {
      "&.MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#f2000a"
      }
    }
  })
);
