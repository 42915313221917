import React, { ReactElement, ReactNode } from "react";
import css from "./Layout.module.css";
import Topbar from "../Components/Topbar/Topbar";
import BackDrawer from "../Components/BackDrawer/BackDrawer";
import Footer from "../Components/Footer/Footer";

export default Layout;

function Layout({ drawer, children }: Props): ReactElement {
  return (
    <div className={css.container}>
      <Topbar />
      <BackDrawer {...drawer} />
      {children}
      <Footer />
    </div>
  );
}

interface Props {
  children: ReactNode;
  drawer: {
    showDrawer: boolean;
    setDrawer: (state: boolean) => void;
    toggleDrawer: () => void;
    drawerContent: ReactElement;
  };
}
