import React from "react";
import { Snackbar } from "@material-ui/core";

export default NoticeSnackbar;

function NoticeSnackbar(props: { error: string | null; setError: (error: string | null) => void }) {
  const error = props.error;
  let errorMsg = "";
  if (error)
    switch (error) {
      case "Network Error":
        errorMsg = "Erro de conexão";
        break;
      default:
        errorMsg = "Erro no servidor";
        break;
    }

  return (
    <Snackbar
      open={!!props.error}
      onClose={() => props.setError(null)}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{errorMsg}</span>}
    />
  );
}
