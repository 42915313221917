import React, { Fragment } from "react";
import { ListItem, createStyles, makeStyles, IconButton, Popover } from "@material-ui/core";
import css from "./ContactsListItem.module.css";
import { PhoneOutlined, SmartphoneOutlined, BusinessOutlined } from "@material-ui/icons";
import { formatPhone, formatCellPhone } from "../../../Utils/fieldFormat";

export default ContactsListItem;

function ContactsListItem(props: any) {
  const { content: contact } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  function handleClose(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(null);
    event.stopPropagation();
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const classes = useStyles();

  const phoneNumber = formatPhone(contact.phoneNumber === null ? "" : contact.phoneNumber);
  const cellPhoneNumber = formatCellPhone(
    contact.cellPhoneNumber === null ? "" : contact.cellPhoneNumber
  );

  return (
    <div>
      <ListItem
        onClick={() => props.openContact(contact.id)}
        className={`${classes.Item} ${css.listItem}`}
        dense
        button
      >
        <div className={css.listItemData}>
          <div className={css.itemName}>{contact.name}</div>
          <div className={css.itemEmail}>{contact.email}</div>
          <div className={css.listItemPhone}>
            {phoneNumber !== "" ? (
              <a href={"tel:" + contact.phoneNumber} className={css.itemPhone}>
                <PhoneOutlined className={classes.icon} />
                <div className={css.phoneNumber}>{phoneNumber}</div>
              </a>
            ) : null}
            {cellPhoneNumber !== "" ? (
              <a href={"tel:" + contact.cellPhoneNumber} className={css.itemCellPhone}>
                <SmartphoneOutlined className={classes.icon} />
                <div className={css.phoneNumber}>{cellPhoneNumber}</div>
              </a>
            ) : null}
          </div>
        </div>

        <div title={`Contato de ${contact.contactOrigin}`} className={css.itemContactOrigin}>
          {contact.id !== contact.contactOriginId ? (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                aria-describedby={id}
                onClick={handleClick}
              >
                <BusinessOutlined className={classes.iconOrigin} />
                <span className={css.originTitle}>origem</span>
              </IconButton>
              <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right"
                }}
              >
                Contato de {contact.contactOrigin}
              </Popover>
            </Fragment>
          ) : (
            undefined
          )}
        </div>
      </ListItem>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    Item: {
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderStyle: "solid",
      borderBottomWidth: "1px",
      display: "grid",
      paddingLeft: "0px",
      paddingRight: "8px",
      height: "100px",
      gridTemplateColumns: "auto 1fr"
    },
    list: {
      paddingTop: "0",
      paddingBottom: "0"
    },

    icon: {
      color: "#125ad3",
      width: "0.85em",
      height: "0.85em"
    },

    iconDate: {
      color: "#737373",
      fontSize: "20px"
    },
    button: {
      backgroundColor: "#125ad3",
      alignSelf: "center",
      justifySelf: "center",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    },
    iconButton: {
      padding: "4px",
      "& .MuiIconButton-label": {
        display: "grid"
      }
    },
    iconOrigin: {
      alignSelf: "center",
      justifySelf: "center",
      color: "#125ad3",
      width: "0.85em",
      height: "0.85em"
    },
    popover: {
      "& .MuiPaper-root": {
        padding: "8px"
      }
    }
  })
);
