import React from "react";
import css from "./ToolbarList.module.css";
import { FilterListOutlined, RefreshOutlined, SortOutlined } from "@material-ui/icons";
import { IconButton, makeStyles, createStyles } from "@material-ui/core";

export default ToolbarList;

function ToolbarList({
  refresh,
  currentSearch,
  isFilterActive,
  setOrder,
  order,
  clearFilters
}: Props) {
  const classes = useStyles();

  return (
    <div className={css.listHeader}>
      <div className={css.searchResults}>
        {currentSearch ? (
          <p className={css.descSearch}>
            Resultados para <span className={css.descSearchKey}>"{currentSearch}"</span>
          </p>
        ) : null}
      </div>
      <div title="Ordenar data" className={css.sort}>
        <IconButton
          className={classes.iconButton}
          onClick={() =>
            setOrder(order => {
              if (order === "ASC") return "DESC";
              else return "ASC";
            })
          }
        >
          <SortOutlined style={{ transform: order === "ASC" ? "scaleY(-1)" : "scaleY(1)" }} />
        </IconButton>
      </div>
      <div title="Limpar filtros" className={css.filter}>
        {isFilterActive ? (
          <IconButton className={classes.iconButton} onClick={clearFilters}>
            <FilterListOutlined className={classes.iconFilter} />
          </IconButton>
        ) : null}
      </div>
      <div title="Atualizar" className={css.refresh}>
        <IconButton className={classes.iconButton} onClick={refresh}>
          <RefreshOutlined />
        </IconButton>
      </div>
    </div>
  );
}

interface Props {
  refresh: () => void;
  currentSearch?: string;
  isFilterActive?: boolean;
  setOrder: (setOrder: SetOrderFunc) => void;
  order: "ASC" | "DESC";
  clearFilters: () => void;
}

type Order = "ASC" | "DESC";
type SetOrderFunc = (newOrder: Order) => Order;

const useStyles = makeStyles(() =>
  createStyles({
    iconFilter: {
      color: "#125ad3"
    },
    iconButton: {
      padding: "4px"
    }
  })
);
