import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  makeStyles,
  createStyles,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Divider
} from "@material-ui/core";
import { ProcMovementType } from "../../../declarations";
import { CloseOutlined } from "@material-ui/icons";
import css from "./ProcMovement.module.css";
import apiCaller from "../../../Utils/apiCaller";

export default ProcMovement;
function ProcMovement(props: {
  procMovement: ProcMovementType;
  setOpenProcMovement: (state: number) => void;
}) {
  let { procMovement } = props;

  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  let dialogContent = <LinearProgress className={classes.loading} />;
  if (!loading) {
    dialogContent = <h2>{JSON.stringify(procMovement)}</h2>;
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => props.setOpenProcMovement(0)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => props.setOpenProcMovement(0)}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {dialogContent}
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "70vw",
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          minWidth: "100vw",
          minHeight: "100vh"
        }
      }
    },
    dialogContent: {
      padding: "0"
    },
    dialogTitle: {
      backgroundColor: "#125ad3",
      color: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    },
    dialogActions: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      gridGap: "10px",
      padding: "8px 24px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
      "@media only screen and (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        padding: "8px 10px"
      }
    },
    toggleButton: {
      height: "30px",
      display: "grid",
      padding: "0px 8px"
    },
    editIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#fff"
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      color: "#fff"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    picker: {
      width: "180px",
      margin: "8px 16px 16px"
    },

    listPicker: {
      width: "min-content",
      display: "grid"
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    }
  })
);
