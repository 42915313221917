import React, { useState, Fragment, ChangeEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Divider,
  makeStyles,
  createStyles,
  LinearProgress,
  TextField,
  Switch,
  Select,
  Input,
  MenuItem,
  IconButton
} from "@material-ui/core";
import {
  LocationOnOutlined,
  NotesOutlined,
  CloseOutlined,
  EventOutlined,
  HowToRegOutlined,
  DateRangeOutlined
} from "@material-ui/icons/";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import css from "./AssignmentEdit.module.css";
import { parse } from "date-fns";
import apiCaller from "../../../Utils/apiCaller";
import { UserFromGroup, AssignmentType } from "../../../declarations";
import { formatWithOptions } from "date-fns/fp";

export default AssignmentEdit;

function AssignmentEdit(props: Props) {
  const { openEdit, setOpenEdit, assignment, updateAssignment, linkedUsers } = props;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  assignment.startTime = assignment.startTime.trim();
  assignment.endTime = assignment.endTime.trim();

  let startTime = parse(assignment.startTime, "HH:mm", new Date());
  let endTime = parse(assignment.endTime, "HH:mm", new Date());
  const [state, setState] = useState<State>({
    subject: assignment.subject,
    location: assignment.location,
    notes: assignment.notes,
    startDate: assignment.startDate,
    endDate: assignment.endDate,
    startTime: startTime,
    endTime: endTime,
    allDay: assignment.allDay,
    assignedUserId: assignment.assignedUserId,
    assignedUserName: assignment.assignedUserName
  });

  const classes = useStyles();
  const [modified, setModified] = useState<Set<keyof State>>(new Set());
  let dialogContent = <LinearProgress className={classes.loading} />;

  const handleChange = (field: keyof State) => (event: ChangeEvent<any>) => {
    setState({ ...state, [field]: event.target.value });
    setModified(s => new Set([...Array.from(s.values()), field]));
    if (field === "assignedUserId") {
      setModified(s => new Set<keyof State>([...Array.from(s.values()), "assignedUserName"]));
      setState(s => ({
        ...s,
        assignedUserName: (linkedUsers.find(lu => lu.id === event.target.value) as UserFromGroup)
          .contactName
      }));
    }
  };

  function handleDateChange(date: Date | null, field: keyof State) {
    setModified(s => new Set([...Array.from(s.values()), field]));
    setState(s => {
      if (field === "startDate" && date!.getTime() > state.endDate.getTime()) {
        s.endDate = date!;
        setModified(s => new Set<keyof State>([...Array.from(s.values()), "endDate"]));
      }
      if (field === "endDate" && date!.getTime() < state.startDate.getTime()) {
        s.startDate = date!;
        setModified(s => new Set<keyof State>([...Array.from(s.values()), "startDate"]));
      }
      if (field === "startTime" && date!.getTime() > state.endTime!.getTime()) {
        s.endTime = date!;
        setModified(s => new Set<keyof State>([...Array.from(s.values()), "endTime"]));
      }
      if (field === "endTime" && date!.getTime() < state.startTime!.getTime()) {
        s.startTime = date!;
        setModified(s => new Set<keyof State>([...Array.from(s.values()), "startTime"]));
      }
      return { ...s, [field]: date };
    });
  }

  async function saveUpdate() {
    setLoading(true);
    const stateAssignment = {
      ...state,
      startTime: formatWithOptions({ locale: ptBR }, "HH:mm", state.startTime),
      endTime: formatWithOptions({ locale: ptBR }, "HH:mm", state.endTime)
    };

    const patchObj = {} as any;

    for (let field of Array.from(modified.values())) {
      patchObj[field as any] = stateAssignment[field];
    }

    const { error } = await apiCaller.patch(`/assignments/${assignment.id}`, patchObj);
    if (error) {
      setError(error);
    } else {
      updateAssignment({ ...assignment, ...patchObj });
    }
    setLoading(false);
    setOpenEdit(false);
  }

  if (!loading) {
    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.main}>
            <div title="Período" className={css.itemSubjectDate}>
              <EventOutlined style={{ marginTop: "6px" }} className={classes.icon} />
              <div className={css.allDay}>
                <span>Dia inteiro</span>
                <Switch
                  checked={!!state.allDay}
                  onClick={() => {
                    setState((s: State) => ({ ...s, allDay: !s.allDay }));
                    setModified(s => new Set<keyof State>([...Array.from(s.values()), "allDay"]));
                  }}
                  value={state.allDay}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  className={classes.switchBase}
                />
              </div>
              <div className={css.date}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    DialogProps={{ className: classes.datePicker }}
                    disabled={assignment.recorrency}
                    showTodayButton={true}
                    todayLabel="Hoje"
                    cancelLabel="Cancelar"
                    invalidLabel="Data inválida"
                    invalidDateMessage="Data inválida"
                    className={`${classes.pickerStart} ${css.startDate}}`}
                    format="EEE', 'd 'de' MMMM 'de' yyyy"
                    margin="normal"
                    value={state.startDate}
                    onChange={date => handleDateChange(date, "startDate")}
                  />
                  {!state.allDay ? (
                    <TimePicker
                      DialogProps={{ className: classes.datePicker }}
                      ampm={false}
                      cancelLabel="Cancelar"
                      label=""
                      value={state.startTime}
                      className={`${classes.timeStart} ${css.startDate}}`}
                      onChange={date => handleDateChange(date, "startTime")}
                    />
                  ) : null}
                  <DatePicker
                    DialogProps={{ className: classes.datePicker }}
                    disabled={assignment.recorrency}
                    showTodayButton={true}
                    todayLabel="Hoje"
                    cancelLabel="Cancelar"
                    invalidLabel="Data inválida"
                    invalidDateMessage="Data inválida"
                    className={`${classes.pickerEnd} ${css.startDate}}`}
                    format="EEE', 'd 'de' MMMM 'de' yyyy"
                    margin="normal"
                    value={state.endDate}
                    onChange={date => handleDateChange(date, "endDate")}
                  />

                  {!state.allDay ? (
                    <TimePicker
                      DialogProps={{ className: classes.datePicker }}
                      ampm={false}
                      cancelLabel="Cancelar"
                      label=""
                      value={state.endTime}
                      className={`${classes.timeEnd} ${css.startDate}}`}
                      onChange={date => handleDateChange(date, "endTime")}
                    />
                  ) : null}
                </MuiPickersUtilsProvider>
                <div className={css.recorrency}>
                  {assignment.recorrency ? (
                    <Fragment>
                      <DateRangeOutlined className={classes.icon} />
                      <span title="Recorrência" className={css.recorrencyTitle}>
                        Recorrência
                      </span>
                    </Fragment>
                  ) : (
                    undefined
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <div title="Local" className={css.item}>
              <LocationOnOutlined className={classes.icon} />
              <div className={css.location}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  placeholder="Digite um local"
                  onChange={handleChange("location")}
                  className={`${classes.textField} ${classes.location}`}
                  value={state.location}
                />
              </div>
            </div>
            <Divider />
            <div title="Notas" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.notes}>
                <TextField
                  autoFocus={props.focusNotes}
                  fullWidth
                  placeholder="Digite uma nota"
                  required
                  multiline
                  onChange={handleChange("notes")}
                  className={classes.textFieldMultiLine}
                  value={state.notes}
                />
              </div>
            </div>
            <Divider />
            <div title="Atribuído" className={css.item}>
              <HowToRegOutlined className={classes.icon} />
              <div className={css.assigned}>
                <span className={css.titleAssigned}>Atribuído à</span>
                <Select
                  value={state.assignedUserId}
                  input={<Input name="atribuido" id="atribuido" />}
                  className={classes.select}
                  onChange={handleChange("assignedUserId")}
                >
                  {linkedUsers.map((lu, i) => (
                    <MenuItem key={i} value={lu.id}>
                      {lu.contactName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Dialog
      className={classes.dialog}
      open={openEdit}
      onClose={() => setOpenEdit(false)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className={classes.dialogTitle} id="responsive-dialog-title">
        <div className={css.header}>
          <IconButton
            className={classes.closeIcon}
            title="Fechar janela"
            onClick={() => setOpenEdit(false)}
          >
            <CloseOutlined />
          </IconButton>
          <Button
            className={classes.saveButton}
            onClick={() => saveUpdate()}
            variant="contained"
            color="primary"
            autoFocus
            size="small"
            disabled={modified.size === 0}
          >
            Salvar
          </Button>
        </div>
        <div title="Assunto" className={css.subject}>
          <TextField
            inputProps={{ maxLength: 255 }}
            fullWidth
            placeholder="Digite um assunto"
            required
            multiline
            onChange={handleChange("subject")}
            className={classes.textFieldMultiLine}
            value={state.subject}
          />
        </div>
      </DialogTitle>
      {dialogContent}
    </Dialog>
  );
}

interface Props {
  openEdit: boolean;
  setOpenEdit: (s: boolean) => void;
  assignment: AssignmentType;
  updateAssignment: (newAssignment: AssignmentType) => void;
  linkedUsers: UserFromGroup[];
  focusNotes: boolean;
}

interface State {
  startDate: Date;
  endDate: Date;
  startTime: Date | null;
  endTime: Date | null;
  subject: string;
  location: string;
  notes: string;
  allDay: boolean;
  assignedUserId: number;
  assignedUserName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "72vw",
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogTitle: {
      backgroundColor: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1)"
    },
    dialogContent: {
      padding: "8px 16px"
    },
    saveButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "10px",
      backgroundColor: "#125ad3",
      "&:hover": {
        backgroundColor: "#1c5793"
      }
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      margin: "5px"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    switchBase: {
      "& .MuiSwitch-colorPrimary.Mui-checked": {
        color: "#125ad3"
      },
      "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#125ad3"
      },
      "& .MuiSwitch-switchBase": {
        color: "#c6c6c6"
      }
    },
    pickerStart: {
      width: "185px",
      marginBottom: "16px",
      marginTop: "0px",
      gridRow: "1",
      gridColumn: "1",
      "& .MuiInputBase-root": {
        fontSize: "0.9rem"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        },
        "&.Mui-error:after": {
          borderBottomColor: "#f44336"
        }
      }
    },
    pickerEnd: {
      width: "185px",
      marginBottom: "16px",
      marginTop: "0px",
      gridRow: "2",
      gridColumn: "1",
      "& .MuiInputBase-root": {
        fontSize: "0.9rem"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        },
        "&.Mui-error:after": {
          borderBottomColor: "#f44336"
        }
      }
    },
    timeStart: {
      width: "35px",
      marginBottom: "16px",
      marginTop: "0px",
      gridRow: "1",
      gridColumn: "2",
      "& .MuiInputBase-root": {
        fontSize: "0.9rem"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        },
        "&.Mui-error:after": {
          borderBottomColor: "#f44336"
        }
      }
    },
    timeEnd: {
      width: "35px",
      marginBottom: "16px",
      marginTop: "0px",
      gridRow: "2",
      gridColumn: "2",
      "& .MuiInputBase-root": {
        fontSize: "0.9rem"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        },
        "&.Mui-error:after": {
          borderBottomColor: "#f44336"
        }
      }
    },
    datePicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected:hover ": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#125ad3",
        color: "#fff"
      },
      "& .MuiButton-textPrimary": {
        color: "#125ad3"
      },
      "& .MuiTypography-colorPrimary": {
        color: "#125ad3"
      },
      "& .MuiPickersClockPointer-pointer": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersClock-pin": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersClockPointer-thumb": {
        border: "14px solid #125ad3"
      }
    },
    listPicker: {
      width: "min-content",
      display: "grid"
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    },
    location: {
      width: "240px"
    },
    textField: {
      color: "white",
      height: 48,
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      },

      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    },
    textFieldMultiLine: {
      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#125ad3"
      },
      "& .MuiInput-underline": {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #125ad3"
        },
        "&:after": {
          borderBottom: "2px solid #125ad3"
        }
      }
    },
    select: {
      gridRow: "1",
      gridColumn: "2",
      minWidth: "160px",
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      }
    }
  })
);
