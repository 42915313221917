export default function normalizeDate(utcDateString: string): Date {
  const date = new Date(utcDateString);
  const outputDate = new Date();

  outputDate.setFullYear(date.getUTCFullYear());
  outputDate.setMonth(date.getUTCMonth());
  outputDate.setDate(date.getUTCDate());
  outputDate.setHours(date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), 0);
  return outputDate;
}
