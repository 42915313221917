import React, { useState, Fragment, useEffect } from "react";
import {
  Dialog,
  makeStyles,
  createStyles,
  LinearProgress,
  DialogTitle,
  IconButton,
  DialogContent,
  Divider
} from "@material-ui/core";
import { ContactType } from "../../../declarations";
import {
  CloseOutlined,
  EmailOutlined,
  SmartphoneOutlined,
  PhoneOutlined,
  WorkOutlined,
  BusinessOutlined,
  AssignmentIndOutlined,
  HomeOutlined,
  ContactsOutlined,
  MapOutlined
} from "@material-ui/icons";
import css from "./Contact.module.css";
import apiCaller from "../../../Utils/apiCaller";
import { formatPhone, formatCellPhone, formatDoc } from "../../../Utils/fieldFormat";

export default Contact;
function Contact({
  contact,
  updateContact,
  setOpenContact,
  openContactId
}: {
  contact?: ContactType;
  updateContact: (a: ContactType) => void;
  setOpenContact: (state: number) => void;
  openContactId: number;
}) {
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const [relatedContacts, setRelatedContacts] = useState<ContactType[]>([]);

  useEffect(
    function() {
      (async function() {
        setLoading(true);
        const { data: dataContact } = await apiCaller.get(`/contacts/${openContactId}`);
        const { data: dataRelated } = await apiCaller.get(`/contacts/${openContactId}/related`);

        if (dataRelated) {
          setRelatedContacts(dataRelated.data);
        }
        if (dataContact) {
          updateContact(dataContact.contact);
        }
        setLoading(false);
      })();
    },
    [openContactId, updateContact]
  );

  let dialogContent: JSX.Element | null = null;

  if (contact) {
    const phoneNumber = formatPhone(contact.phoneNumber === null ? "" : contact.phoneNumber);
    const cellPhoneNumber = formatCellPhone(
      contact.cellPhoneNumber === null ? "" : contact.cellPhoneNumber
    );
    const docNumber = formatDoc(contact.CPF === null ? "" : contact.CPF);

    const classInitials = contact.contactClass.trim().split("");
    const classFull = classInitials
      .map((initials: string) => {
        switch (initials) {
          case "C":
            return "cliente";

          case "S":
            return "possível cliente";

          case "I":
            return "cliente interno";

          case "A":
            return "advogado";

          case "E":
            return "estagiário";

          case "U":
            return "administrativo";

          case "F":
            return "fornecedor";

          case "D":
            return "correspondente";

          case "P":
            return "parte";

          case "R":
            return "captador externo";

          case "O":
            return "outros";
        }
      })
      .join(" | ");

    const cityState = [contact.district, contact.city, contact.state]
      .filter(s => s !== null && s !== "")
      .join(" - ");

    const endCompleto = `${contact.address} ${contact.addressComplement} ${cityState}`;
    const stringSearchMap = endCompleto.replace(/[^0-9a-zA-Zçâ-ôÂ-Ôá-úÁ-Úà-ùÀ-Ùã-õÃ-Õºª,.-]/g, "+");

    let relatedContactsList = null;

    if (relatedContacts.length > 0)
      relatedContactsList = (
        <Fragment>
          <Divider />
          <div title="Contatos" className={css.item}>
            <ContactsOutlined className={classes.icon} />
            <div className={css.contentTitle}>Contatos vinculados</div>
          </div>
          <div className={css.listRelatedContacts}>
            {relatedContacts.map(rc => {
              return (
                <div key={rc.id} className={css.itemRelatedContacts}>
                  <div className={css.contact}>{rc.name}</div>
                  <div onClick={() => setOpenContact(rc.id)} className={css.viewContact}>
                    ver
                  </div>
                </div>
              );
            })}
          </div>
        </Fragment>
      );

    dialogContent = (
      <Fragment>
        <DialogContent className={classes.dialogContent}>
          <div className={css.contactSup}>
            <div className={css.itemContacts}>
              <div title="Nome" className={css.name}>
                {contact.name}
              </div>
              <div title="cargo" className={css.cargo}>
                {contact.role}
              </div>
              <div title="Email" className={css.email}>
                <EmailOutlined className={classes.icon} />
                <div className={css.emailAdress}>{contact.email}</div>
              </div>
              <div title="Telefone" className={css.phoneNumbers}>
                <a href={"tel:" + contact.cellPhoneNumber} className={css.phone}>
                  <PhoneOutlined className={classes.icon} />
                  <div className={css.content}>{phoneNumber}</div>
                </a>
                <a href={"tel:" + contact.cellPhoneNumber} className={css.cellPhone}>
                  <SmartphoneOutlined className={classes.icon} />
                  <div className={css.content}>{cellPhoneNumber}</div>
                </a>
              </div>
            </div>
          </div>
          <div className={css.contactInf}>
            <div title="classe" className={css.item}>
              <WorkOutlined className={classes.icon} />
              <div className={css.content}>{classFull}</div>
            </div>
            {contact.id !== contact.contactOriginId ? (
              <div title="Empresa" className={css.item}>
                <BusinessOutlined className={classes.icon} />
                <div className={css.content}>{contact.contactOrigin}</div>
                <div
                  onClick={() => setOpenContact(contact.contactOriginId)}
                  className={css.viewContactOrigin}
                >
                  ver
                </div>
              </div>
            ) : null}
            <div title="CPF | CNPJ" className={css.item}>
              <AssignmentIndOutlined className={classes.icon} />
              <div className={css.content}>{docNumber}</div>
            </div>

            <Divider />
            <div title="Endereço Completo" className={css.item}>
              <HomeOutlined className={classes.icon} />
              {cityState !== "" && cityState !== null ? (
                <Fragment>
                  <a
                    href={`https://www.google.com.br/maps/search/${stringSearchMap}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.contentAdress}
                  >
                    <div title="Endereço" className={css.adress}>
                      {contact.address}
                    </div>
                    <div title="Complemento" className={css.addressComplement}>
                      {contact.addressComplement}
                    </div>
                    <div title="Bairro/Cidade" className={css.adressCity}>
                      {cityState}
                    </div>
                  </a>
                  <IconButton
                    className={classes.viewMaps}
                    title="Visualizar no mapa"
                    href={`https://www.google.com.br/maps/search/${stringSearchMap}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MapOutlined className={classes.iconMap} />
                  </IconButton>{" "}
                </Fragment>
              ) : null}
            </div>
            {relatedContactsList}
          </div>
        </DialogContent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => setOpenContact(0)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => setOpenContact(0)}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? <LinearProgress className={classes.loading} /> : null}
        {dialogContent}
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "70vw",
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogContent: {
      padding: "0"
    },
    dialogTitle: {
      zIndex: 1,
      backgroundColor: "#125ad3",
      color: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    },
    dialogActions: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      gridGap: "10px",
      padding: "8px 24px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
      "@media only screen and (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        padding: "8px 10px"
      }
    },
    toggleButton: {
      height: "30px",
      display: "grid",
      padding: "0px 8px"
    },
    editIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#fff"
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      color: "#fff"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    picker: {
      width: "180px",
      margin: "8px 16px 16px"
    },

    listPicker: {
      width: "min-content",
      display: "grid"
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    },
    viewMaps: {
      gridRow: "1",
      gridColumn: "3",
      alignSelf: "center",
      padding: "4px"
    },
    iconMap: {
      color: "#125ad3",
      width: "0.85em",
      height: "0.85em",
      gridRow: "1",
      gridColumn: "1"
    }
  })
);
