import React, { ReactElement } from "react";
import { SwipeableDrawer, makeStyles, createStyles } from "@material-ui/core";
export default BackDrawer;

function BackDrawer({ drawerContent, setDrawer, showDrawer }: Props): ReactElement {
  const classes = useStyles();

  return (
    <div>
      <SwipeableDrawer
        style={{ zIndex: 2 }}
        className={classes.drawer}
        transitionDuration={226}
        open={showDrawer}
        onOpen={() => setDrawer(true)}
        onClose={() => setDrawer(false)}
      >
        {drawerContent}
      </SwipeableDrawer>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      "& .MuiDrawer-paper": {
        maxHeight: "calc(100vh - 77px)",
        top: "35px"
      }
    }
  })
);

interface Props {
  drawerContent: ReactElement;
  setDrawer: (state: boolean) => void;
  showDrawer: boolean;
}
