import React, { useEffect, useState, Fragment, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  makeStyles,
  createStyles,
  LinearProgress,
  IconButton,
  Button,
  DialogActions
} from "@material-ui/core";
import {
  EditOutlined,
  LocationOnOutlined,
  NotesOutlined,
  PeopleOutlined,
  GavelOutlined,
  HowToRegOutlined,
  CloseOutlined,
  CreateOutlined,
  FolderOutlined,
  EventNoteOutlined,
  DateRangeOutlined,
  AccessTimeOutlined,
  CheckOutlined,
  ContactsOutlined
} from "@material-ui/icons/";
import { ToggleButton } from "@material-ui/lab";
import ptBR from "date-fns/locale/pt-BR";
import apiCaller from "../../../Utils/apiCaller";
import css from "./Assignment.module.css";
import { formatWithOptions } from "date-fns/fp";
import AssignmentEdit from "../AssignmentEdit/AssignmentEdit";
import { normalizeAssignment } from "../Assignments";
import { AssignmentType, UserFromGroup } from "../../../declarations";

export default Assignment;

function Assignment(props: {
  assignment?: AssignmentType;
  updateAssignment: (a: AssignmentType) => void;
  setOpenAssignment: (state: number) => void;
  openAssignmentId: number;
}) {
  let { assignment, updateAssignment } = props;
  const [expandNotes, setExpandNotes] = useState(false);
  const [_, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [questionDialog, setQuestionDialog] = useState({ open: false, confirmed: false });
  const [linkedUsersDialog, setLinkedUsersDialog] = useState({ open: false, confirmed: false });
  const [linkedUsers, setLinkedUsers] = useState<UserFromGroup[]>([]);

  const fetchLinkedUsers = useCallback(
    async function() {
      const { error, data } = await apiCaller.get(
        `/assignments/${props.openAssignmentId}/linked-users`
      );
      if (error) {
        setError(error);
      } else {
        setLinkedUsers(data.data);
      }
      setLoading(false);
    },
    [props.openAssignmentId]
  );

  useEffect(() => {
    (async () => {
      const { error, data } = await apiCaller.get("/assignments/" + props.openAssignmentId);
      if (error) {
        setError(error);
      } else {
        const { assignment: fetchedAssignment } = data;
        updateAssignment(normalizeAssignment(fetchedAssignment));
        await fetchLinkedUsers();
      }
      setLoading(false);
    })();
  }, [fetchLinkedUsers, props.openAssignmentId, setError, updateAssignment]);

  async function setStatus(status: number) {
    setLoading(true);
    const { error } = await apiCaller.patch(
      `/assignments/${props.openAssignmentId}/change-status`,
      {
        status
      }
    );
    if (error) setError(error);
    else updateAssignment({ ...assignment!, status });
    setLoading(false);
    setQuestionDialog(s => ({ ...s, open: true }));
  }

  const classes = useStyles();

  let dialogContent: JSX.Element | null = null;
  let endDate, startDate;

  if (assignment) {
    const dateFormated = (date: Date) => {
      const currentYear = new Date().getFullYear();
      const otherYears = date.getFullYear();

      return currentYear === otherYears
        ? formatWithOptions({ locale: ptBR }, "EEE', 'd 'de' MMMM", date)
        : formatWithOptions({ locale: ptBR }, "EEE', 'd 'de' MMMM 'de' yyyy", date);
    };

    endDate = dateFormated(assignment.endDate);
    startDate = dateFormated(assignment.startDate);

    const today = formatWithOptions({ locale: ptBR }, "EEE', 'd 'de' MMMM", new Date());

    startDate = today === startDate ? "Hoje" : startDate;
    endDate = today === endDate ? "Hoje" : endDate;

    let colorHex = assignment.color.toString(16);
    for (let i = colorHex.length; i < 6; i++) {
      colorHex = "0" + colorHex;
    }
    colorHex = colorHex.slice(4, 6) + colorHex.slice(2, 4) + colorHex.slice(0, 2);

    const assignmentNotes = (
      <Fragment>
        {assignment.notes && assignment.notes.length > 300 && !expandNotes
          ? assignment.notes.slice(0, 300)
          : assignment.notes}
        {assignment.notes && assignment.notes.length > 300 ? (
          <Button onClick={() => setExpandNotes(s => !s)} className={classes.button}>
            Ler {expandNotes ? "menos" : "mais"}...
          </Button>
        ) : null}
      </Fragment>
    );

    let assignmentMode;

    switch (assignment.mode) {
      case 0:
        assignmentMode = "Normal";
        break;
      case 1:
        assignmentMode = "Particular";
        break;
      case 2:
        assignmentMode = "Providência";
        break;
      case 3:
        assignmentMode = "Prazo";
        break;
      case 4:
        assignmentMode = "Atividade externa";
        break;
      case 5:
        assignmentMode = "Diligência";
        break;
    }

    dialogContent = (
      <Fragment>
        <DialogContent>
          <div className={css.main}>
            <div className={css.itemSubjectDate}>
              <div title="Cor" className={css.color} style={{ backgroundColor: `#${colorHex}` }} />
              <div title="Assunto" className={css.subject}>
                {assignment.subject}
              </div>
              <div title="Período" className={css.date}>
                {startDate === endDate ? (
                  <Fragment>
                    <div className={css.startDate}>
                      {!assignment.allDay
                        ? `${startDate} - ${assignment.startTime} às ${assignment.endTime} `
                        : startDate}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className={css.startDate}>
                      {!assignment.allDay ? `${startDate} às ${assignment.startTime}` : startDate}
                    </div>
                    <div className={css.startDate}>
                      {!assignment.allDay ? `${endDate} às ${assignment.endTime}` : endDate}
                    </div>
                  </Fragment>
                )}
              </div>

              <div className={css.recorrency}>
                {assignment.recorrency ? (
                  <Fragment>
                    <DateRangeOutlined className={classes.icon} />
                    <span title="Recorrência" className={css.recorrencyTitle}>
                      Recorrência
                    </span>
                  </Fragment>
                ) : (
                  undefined
                )}
              </div>
            </div>
            <div title="Modo" className={css.item}>
              <EventNoteOutlined className={classes.icon} />
              <div className={css.mode}>{assignmentMode}</div>
            </div>
            <div title="Local" className={css.item}>
              <LocationOnOutlined className={classes.icon} />
              <div className={css.location}>{assignment.location}</div>
            </div>
            <div title="Notas" className={css.item}>
              <NotesOutlined className={classes.icon} />
              <div className={css.notes}>{assignmentNotes}</div>
            </div>
            <Divider />
            <div title="Cliente" className={css.item}>
              <ContactsOutlined className={classes.icon} />
              <div className={css.clientName}>{assignment.clientName}</div>
            </div>
            <div title="Pasta/Caso" className={css.item}>
              <FolderOutlined className={classes.icon} />
              <div className={css.processFolder}>
                {assignment.folderNumber}{" "}
                {assignment.folderTitle ? `- ${assignment.folderTitle}` : null}
              </div>
            </div>
            <div title="Processo" className={css.item}>
              <GavelOutlined className={classes.icon} />
              <div className={css.process}>
                {((assignment.processNumberUnif && assignment.processNumberUnif.trim()) || "") ===
                ""
                  ? assignment.processNumber
                  : assignment.processNumberUnif}
              </div>
            </div>
            <Divider />
            <div title="Atribuído" className={css.item}>
              <HowToRegOutlined className={classes.icon} />
              <div className={css.assigned}>{assignment.assignedUserName}</div>
            </div>
            <div title="Vinculados" className={css.item}>
              <PeopleOutlined className={classes.icon} />
              <div className={css.linkedUsers}>
                {linkedUsers.length > 1 ? (
                  <Fragment>
                    {`Você e mais ${linkedUsers.length - 1} usuários vinculados`}
                    <div
                      className={css.linkedUsersLink}
                      key="0"
                      onClick={() => setLinkedUsersDialog(s => ({ ...s, open: true }))}
                    >
                      Visualizar
                    </div>
                  </Fragment>
                ) : (
                  `Somente você está vinculado(a)`
                )}
              </div>
            </div>
            <div title="Criado por" className={css.item}>
              <CreateOutlined className={classes.icon} />
              <div className={css.createBy}>Criado por: {assignment.createdBy} </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <span className={css.statusQuestion}>Qual o status deste prazo?</span>
          <div className={css.toggleButtonGroup}>
            <ToggleButton
              value=""
              onClick={() => setStatus(0)}
              disabled={assignment.status === 0 || assignment.permissionLevel < 1}
              className={classes.toggleButton}
              selected={assignment.status === 0}
              title="Pendente"
            >
              <AccessTimeOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Pendente</span>
            </ToggleButton>
            <ToggleButton
              value=""
              onClick={() => setStatus(1)}
              disabled={assignment.status === 1 || assignment.permissionLevel < 1}
              className={classes.toggleButton}
              title="Concluído"
              selected={assignment.status === 1}
            >
              <CheckOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Concluído</span>
            </ToggleButton>
            <ToggleButton
              value=""
              onClick={() => {
                setStatus(2);
              }}
              disabled={assignment.status === 2 || assignment.permissionLevel < 1}
              className={classes.toggleButton}
              title="Cancelado"
              selected={assignment.status === 2}
            >
              <CloseOutlined fontSize="small" style={{ color: "#125ad3" }} />
              <span className={css.statusTitle}>Cancelado</span>
            </ToggleButton>
          </div>
        </DialogActions>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        open={true}
        onClose={() => props.setOpenAssignment(0)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle}>
          <div className={css.header}>
            <IconButton
              className={classes.closeIcon}
              title="Fechar janela"
              onClick={() => props.setOpenAssignment(0)}
            >
              <CloseOutlined />
            </IconButton>
            <IconButton
              disabled={assignment && assignment.permissionLevel < 1}
              className={classes.editIcon}
              title={"Editar prazo"}
              onClick={() => setOpenEdit(true)}
            >
              <EditOutlined />
            </IconButton>
          </div>
        </DialogTitle>
        {loading ? <LinearProgress className={classes.loading} /> : null}
        {dialogContent}
        <Dialog
          open={questionDialog.open}
          onClose={() => setQuestionDialog({ open: false, confirmed: false })}
        >
          <span className={css.dialogTitle}>Deseja adicionar uma nota ao prazo?</span>
          <DialogActions>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog({ open: false, confirmed: false });
                props.setOpenAssignment(0);
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Mais tarde
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={() => {
                setQuestionDialog({ open: false, confirmed: true });
                setOpenEdit(true);
              }}
              color="primary"
              autoFocus
              size="small"
            >
              Agora
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={linkedUsersDialog.open}
          onClose={() => setLinkedUsersDialog({ open: false, confirmed: false })}
        >
          <DialogTitle className={classes.dialogTitleLinked}>
            <div className={css.header}>
              <IconButton
                className={classes.closeIconLinked}
                title="Fechar janela"
                onClick={() => {
                  setLinkedUsersDialog({ open: false, confirmed: false });
                }}
              >
                <CloseOutlined />
              </IconButton>
              <span className={css.dialogTitleLinked}>Usuários vinculados</span>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className={css.dialogLinkedUsers}>
              {linkedUsers.map(lu => {
                return (
                  <li className={css.linkedUserList} key={lu.id}>
                    {lu.contactName}
                  </li>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      </Dialog>
      {assignment && openEdit ? (
        <AssignmentEdit
          linkedUsers={linkedUsers}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          updateAssignment={updateAssignment}
          assignment={assignment}
          focusNotes={questionDialog.confirmed}
        />
      ) : null}
    </Fragment>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      "& .MuiDialog-paperWidthSm": {
        minWidth: "70vw",
        minHeight: "90vh",
        "@media only screen and (max-width: 820px)": {
          width: "100%",
          height: "100%",
          margin: "0",
          maxWidth: "100%",
          maxHeight: "none",
          borderRadius: "0"
        }
      }
    },
    dialogTitle: {
      backgroundColor: "#125ad3",
      color: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    },
    dialogTitleLinked: {
      backgroundColor: "#fff",
      gridTemplateColumns: "1fr 1fr",
      padding: "0px 0px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0), 0px 1px 10px 0px rgba(0, 0, 0, 0.1)"
    },
    dialogActions: {
      display: "grid",
      gridTemplateColumns: "1fr auto",
      gridGap: "10px",
      padding: "8px 24px",
      boxShadow:
        "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
      "@media only screen and (max-width: 600px)": {
        gridTemplateColumns: "1fr",
        padding: "8px 10px"
      }
    },
    toggleButton: {
      height: "30px",
      display: "grid",
      padding: "0px 8px",
      color: "rgba(0, 0, 0, 0.7)",
      "&.MuiToggleButton-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.4)"
      }
    },
    editIcon: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      color: "#fff",
      padding: "8px"
    },
    closeIcon: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      color: "#fff",
      padding: "8px"
    },
    closeIconLinked: {
      gridColumn: "1",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "start",
      padding: "4px"
    },
    loading: {
      height: "5px",
      position: "relative",
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#125ad3"
      }
    },
    picker: {
      width: "180px",
      margin: "8px 16px 16px"
    },

    listPicker: {
      width: "min-content",
      display: "grid"
    },

    icon: {
      color: "#737373",
      fontSize: "20px",
      gridRow: "1",
      gridColumn: "1"
    },
    button: {
      color: "#125ad3",
      lineHeight: "0px"
    },
    dialogButton: {
      gridColumn: "2",
      gridRow: "1",
      alignSelf: "center",
      justifySelf: "end",
      margin: "10px",
      color: "#125ad3"
    }
  })
);
