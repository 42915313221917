import React from "react";
import css from "./AssignmentsSidebar.module.css";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  createStyles,
  Button
} from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import {
  CheckBoxOutlined,
  ReorderOutlined,
  TodayOutlined,
  CheckOutlined,
  Filter7Outlined,
  Filter3Outlined,
  CloseOutlined,
  AccessTimeOutlined,
  FilterListOutlined
} from "@material-ui/icons";
import { UserFromGroup } from "../../../declarations";
import AutoComplete from "../../../Components/AutoComplete/AutoComplete";

export default AssignmentsSidebar;

function AssignmentsSidebar({
  clearFilters,
  isFilterActive,
  setSelectedDate,
  selectedDate,
  displayMode,
  setDisplayMode,
  setSelectedAssigned,
  selectedAssigned,
  setDrawer,
  userGroup
}: Props) {
  const closeDrawer = () => (setDrawer ? setDrawer(false) : null);

  const periodDisabled = [1, 5, 6].includes(displayMode);

  const classes = useStyles();

  return (
    <div className={css.sidebarContainer}>
      <div className={css.sidebarSup}>
        <div className={css.sidebarIconTitle}>
          <CheckBoxOutlined className={classes.IconCheckBox} />
          <p className={css.sidebarSupTitle}>Prazos</p>
        </div>
        <div className={css.userAssigned}>
          <AutoComplete
            userGroup={userGroup}
            selectedAssigned={selectedAssigned}
            setSelectedAssigned={setSelectedAssigned}
          />
        </div>
      </div>
      <div className={css.sidebarInf}>
        <List className={classes.sidebarInfList}>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 0}
            onClick={() => {
              setDisplayMode(0);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ReorderOutlined />
            </ListItemIcon>
            <ListItemText primary="Todos" />
          </ListItem>

          <Divider />
          <ListSubheader disableSticky={true}>FILTROS</ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 1}
            onClick={() => {
              setDisplayMode(1);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <TodayOutlined />
            </ListItemIcon>
            <ListItemText primary="Hoje" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 2}
            onClick={() => {
              setDisplayMode(2);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccessTimeOutlined />
            </ListItemIcon>
            <ListItemText primary="Pendentes" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 4}
            onClick={() => {
              setDisplayMode(4);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CheckOutlined />
            </ListItemIcon>
            <ListItemText primary="Concluídos" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 3}
            onClick={() => {
              setDisplayMode(3);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <CloseOutlined />
            </ListItemIcon>
            <ListItemText primary="Cancelados" />
          </ListItem>
          <Divider />
          <ListSubheader disableSticky={true}>Próximos</ListSubheader>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 5}
            onClick={() => {
              setDisplayMode(5);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Filter3Outlined />
            </ListItemIcon>
            <ListItemText primary="3 Dias" />
          </ListItem>
          <ListItem
            className={classes.listItemButton}
            button
            selected={displayMode === 6}
            onClick={() => {
              setDisplayMode(6);
              closeDrawer();
            }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Filter7Outlined />
            </ListItemIcon>
            <ListItemText primary="7 Dias" />
          </ListItem>
          <Divider />

          <ListSubheader disableSticky={true}>Período</ListSubheader>
          <div className={css.pickerPeriod}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                disabled={periodDisabled}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                maxDate={selectedDate.toDate ? selectedDate.toDate : undefined}
                inputVariant="outlined"
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="De"
                value={selectedDate.fromDate}
                onChange={date => {
                  date!.setHours(0, 0, 0, 0);
                  setSelectedDate(date, "fromDate");
                }}
              />
              <KeyboardDatePicker
                DialogProps={{ className: classes.datePicker }}
                showTodayButton={true}
                todayLabel="Hoje"
                cancelLabel="Cancelar"
                minDate={selectedDate.fromDate}
                disabled={!selectedDate.toDate || periodDisabled}
                inputVariant="outlined"
                invalidLabel="Data inválida"
                invalidDateMessage="Data inválida"
                className={classes.picker}
                format="dd/MM/yyyy"
                margin="normal"
                label="Até"
                value={selectedDate.toDate}
                onChange={date => {
                  date!.setHours(23, 59, 59, 0);
                  setSelectedDate(date, "toDate");
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </List>
      </div>
      <div className={css.filter}>
        <Button
          fullWidth
          disabled={!isFilterActive}
          className={`${classes.button}`}
          onClick={clearFilters}
          variant="contained"
          color="primary"
        >
          <FilterListOutlined className={classes.iconFilter} />
          <span style={{ paddingRight: "20px" }}>Limpar filtros</span>
        </Button>
      </div>
    </div>
  );
}

interface Props {
  clearFilters: () => void;
  isFilterActive: boolean;
  setSelectedDate: (value: Date | null, field: "toDate" | "fromDate") => void;
  selectedDate: { fromDate: Date | null; toDate: Date | null };
  displayMode: number;
  setDisplayMode: (mode: number) => void;
  setSelectedAssigned: (index: UserFromGroup | null) => void;
  selectedAssigned: UserFromGroup | null;
  setDrawer?: (state: boolean) => void;
  userGroup: UserFromGroup[];
}

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      "&.Mui-focused": {
        color: "#125ad3"
      }
    },

    select: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "2px solid #125ad3"
      },
      "&:after": {
        borderBottom: "2px solid #125ad3"
      }
    },

    listItemButton: {
      "&.Mui-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      }
    },
    listItemIcon: {
      minWidth: "40px"
    },
    IconCheckBox: {
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center",
      justifySelf: "center",
      marginRight: "10px",
      fontSize: "40px",
      color: "#1a5dce",
      "@media only screen and (min-width: 600px)": {
        width: "0.8em",
        height: "0.8em"
      },
      "@media only screen and (max-width: 600px)": {
        width: "0.7em",
        height: "0.7em"
      }
    },

    sidebarInfList: {
      height: "calc(var(--vh, 1vh) * 100 - 265px)",
      padding: "0px"
    },

    datePicker: {
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected:hover ": {
        backgroundColor: "#125ad3"
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: "#125ad3",
        color: "#fff"
      },
      "& .MuiButton-textPrimary": {
        color: "#125ad3"
      },
      "& .MuiTypography-colorPrimary": {
        color: "#125ad3"
      }
    },

    button: {
      backgroundColor: "#125ad3",
      fontSize: "0.8rem",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#0039c4"
      }
    },
    picker: {
      width: "180px",
      margin: "8px 16px 16px",

      "& label.Mui-focused": {
        color: "#125ad3"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "#125ad3"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#125ad3"
        }
      }
    },
    iconFilter: {
      color: "#fff",
      fontSize: "1.2rem",
      padding: "0 5px"
    }
  })
);
