import React from "react";
import css from "./Footer.module.css";
import { makeStyles, createStyles } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import { NavLink } from "react-router-dom";

export default Footer;

function Footer() {
  const classes = useStyles();

  function selectedMenu(menu: string) {
    if (window.location.pathname.indexOf(menu) === 0) {
      return { backgroundColor: "#125ad3" };
    }
  }

  return (
    <div className={css.footer}>
      <div className={css.menu}>
        <NavLink className={css.menuItem} to="/prazos">
          <CheckBoxOutlinedIcon className={classes.icon} />
          <span className={css.menuTitle}>Prazos</span>
          <div style={selectedMenu("/prazos")} className={css.menuSelected} />
        </NavLink>
        <NavLink className={css.menuItem} to="/contatos">
          <PeopleOutlinedIcon className={classes.icon} />
          <span className={css.menuTitle}>Contatos</span>
          <div style={selectedMenu("/contatos")} className={css.menuSelected} />
        </NavLink>
        {/* <NavLink className={css.menuItem} to="/andamentos">
          <PeopleOutlinedIcon className={classes.icon} />
          <span className={css.menuTitle}>Contatos</span>
          <div style={selectedMenu("/andamentos")} className={css.menuSelected} />
        </NavLink> */}
      </div>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      gridRow: "1",
      gridColumn: "1",
      alignSelf: "center",
      justifySelf: "center",
      fontSize: "20px",
      color: "#737373"
    }
  })
);
